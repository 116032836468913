<template>
  <button
    ref="btnElement"
    :disabled="
      isExecutingOnClick ||
      (objectInfo.isContents && !objectInfo.editInRow) ||
      isDisableEdit
    "
    :style="{
      // Background
      backgroundColor: attributes.image ? 'transparent' : attributes.bgColor,
      backgroundImage: attributes.image && `url(${imgPath})`,
      // Border radius
      borderRadius: attributes.borderCornerRadius,
      // Font
      color: isExecutingOnClick ? 'lightgray' : attributes.foreColor,
      fontSize: attributes.fontSize && attributes.fontSize,
      // Align
      justifyContent:
        (attributes.align.row && attributes.align.row) || 'center',
      alignItems:
        (attributes.align.column && attributes.align.column) || 'center',
      // Padding
      padding:
        (!attributes.labelWidth ||
          attributes.labelWidth.toString() === '0' ||
          attributes.title) &&
        !hasPadding &&
        0,
      // is Executing or disableEdit? -> set opacity
      opacity: isExecutingOnClick || isDisableEdit ? 0.4 : 1,
    }"
    :class="{
      'no-border':
        attributes.image ||
        !attributes.bgColor ||
        attributes.bgColor === '#00000000' ||
        attributes.bgColor === 'transparent', //|| attributes.bgColor,
      noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
      'keep-aspect-ratio': attributes.keepAspectRatio,
    }"
    @click="!noEditInRow && onClick()"
  >
    {{ attributes.labelWidth !== "0" ? attributes.title : "" }}
  </button>
</template>

<script>
import {
  inject,
  ref,
  Ref,
  PropType,
  watch,
  watchEffect,
  ComputedRef,
  onMounted,
  computed,
} from "vue";

import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { getImagePath } from "../../composables/helperFunctions/ImageHelper";
import dragAndDrop from "../../composables/DragAndDrop";

export default {
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    paddings: { type: Object, required: true },
    isDisableEdit: { type: Boolean, required: true },
    containerWidth: { type: Number, default: 0 },
    containerHeight: { type: Number, default: 0 },
  },
  setup(props) {
    // const isDesigner = dragAndDrop.getIsDesigner();
    // on click method is being executed
    const isExecutingOnClick = ref(false);

    /**
     * on click button
     */
    const onClick = async () => {
      // if (isDesigner) return;
      if (isExecutingOnClick.value) return;
      isExecutingOnClick.value = true;

      //
      // Execute method
      const method = props.attributes?.method;
      if (method)
        await xoneAttributesHandler.executeMethod(method, props.xoneDataObject);

      //
      // Execute onclick
      const onClick = props.attributes?.onClick;
      try {
        if (onClick)
          await props.xoneDataObject.DoRunScriptAsync(
            `let e = { target: '${props.attributes.name}',objItem: '${props.attributes.name}' };
              ${onClick}`
          );
      } catch (ex) {
        console.error(ex);
      }

      //
      // Execute binded event in script
      await xoneAttributesHandler.executeBindedEvent(
        props.xoneDataObject,
        props.attributes,
        "onclick",
        {}
      );

      //
      // Is Custom Msgbox
      if (objectInfo.isMsgBox && props.attributes.buttonOption)
        objectInfo.onMsgBoxOptionSelected &&
          objectInfo.onMsgBoxOptionSelected(props.attributes.buttonOption);

      isExecutingOnClick.value = false;
    };

    /**
     * Contents Info
     * @type {import('../../composables/AppDataHandler').Objectinfo}
     */
    const objectInfo = inject("objectInfo");

    //
    // Offset height / width when scaled

    /**
     * button element
     * @type {Ref<HTMLElement>}
     */
    const btnElement = ref();

    const fitSizeToContainer = () => {
      xoneAttributesHandler.fitHeightToContainer(props.attributes, btnElement);
      xoneAttributesHandler.fitWidthToContainer(props.attributes, btnElement);
    };

    onMounted(() => fitSizeToContainer());

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<import('../../composables/AppDataHandler').Breadcumb>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Provide breadcumbId info to child components
    const breadcumbId = inject("breadcumbId");

    watchEffect(() => {
      if (breadcumbId === lastBreadcumb?.value?.id) fitSizeToContainer();
    });

    watch(
      () => props.containerWidth,
      () => fitSizeToContainer()
    );
    watch(
      () => props.containerHeight,
      () => fitSizeToContainer()
    );

    // if selected group is current group fit size to container
    /**
     * groupId
     * @type {number}
     */
    const groupId = inject("groupId");

    /**
     * activeGroup
     * @type {Ref<number>}
     */
    const { activeGroup } = inject("groupHandler");

    watch(
      () => activeGroup.value,
      (newValue) => {
        if (groupId !== newValue) return;
        fitSizeToContainer();
      }
    );

    const imgPath = ref(
      props.attributes.image?.includes("icons") ||
        props.attributes.image?.includes("files")
        ? props.attributes.image
        : `/icons/${props.attributes.image}`
    );

    watchEffect(async () => {
      if (props.attributes.image) {
        imgPath.value = await getImagePath(props.attributes.image);
      }
    });

    /**
     * hasPadding
     * @type {ComputedRef<boolean>}
     */
    const hasPadding = computed(
      () =>
        props.attributes.paddings.top ||
        props.attributes.paddings.right ||
        props.attributes.paddings.bottom ||
        props.attributes.paddings.left
    );

    return {
      btnElement,
      onClick,
      objectInfo,
      isExecutingOnClick,
      imgPath,
      hasPadding,
    };
  },
};
</script>

<style scoped>
/* button */
button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  align-self: center;
  transition: all 0.3s;
  border: var(--border-width) solid var(--border-color);

  padding-top: var(--padding-top);
  padding-right: var(--padding-right);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);

  box-shadow: 0px 0px var(--elevation) 0 rgba(0, 0, 0, 0.2);

  /* background-size: 100% 100%; */
}

/* button:focus {
  outline: auto;
} */

.keep-aspect-ratio {
  background-size: contain;
}

.no-border {
  border: none;
}

.noEditInRow {
  cursor: pointer;
  pointer-events: none;
}
</style>