<template>
  <canvas
    :id="`chart-${breadcumbId}-${attributes.name}${objectIndex}`"
  ></canvas>
</template>

<script>
import {
  inject,
  ref,
  watchEffect,
  Ref,
  PropType,
  onUnmounted,
  nextTick,
} from "vue";

import Chart from "chart.js";

import { PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
// import { hexToRgbA } from "../../../composables/helperFunctions/ChartsHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";

export default {
  name: "ChartPie",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * objectInfo
     * @type {import('../../../composables/AppDataHandler').Objectinfo}
     */
    const objectInfo = inject("objectInfo");

    const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    let ctx;

    let refreshTimeOut;
    let refreshTime = 100;

    const refresh = async () => {
      if (!ctx) return;

      if (refreshTimeOut) clearTimeout(refreshTimeOut);

      refreshTimeOut = setTimeout(async () => {
        if (!contents.value)
          contents.value = await props.xoneDataObject.getContents(
            props.attributes.contents
          );

        // Load contents data
        await contents.value.loadAll(false);

        /**
         * contents data
         * @type {Ref<Array<XoneDataObject>>}
         */
        const data = {
          labels: [],
          datasets: [],
        };

        // Fill chart data
        let chartSerieTitle;
        let chartName;
        let chartTitle;
        let serieColor = "";

        /**
         * m_xmlNode
         * @type {{m_xmlNode:XmlNode}}
         */
        const { m_xmlNode } = contents.value;

        m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
          // Chart serie title
          if (e.getAttrValue("chart-serie-title") === "true")
            chartSerieTitle = e.getAttrValue("name");

          // Chart value
          if (e.getAttrValue("chart-value") === "true") {
            chartName = e.getAttrValue("name");
            chartTitle = e.getAttrValue("title");
          }

          // Chart serie color
          if (e.getAttrValue("chart-serie-color") === "true")
            serieColor = e.getAttrValue("name");
        });

        // Clear data
        data.datasets = [];
        data.labels = [];

        const dataset = { label: chartTitle };

        dataset.data = [];
        dataset.backgroundColor = [];
        dataset.borderColor = [];
        dataset.borderWidth = 1;

        // Create data
        for (let i = 0; i < contents.value.length; i++) {
          /**
           * rowDataObject
           * @type {XoneDataObject}
           */
          const rowDataObject = await contents.value.get(i);

          // Chart Serie Title
          if (chartSerieTitle) data.labels.push(rowDataObject[chartSerieTitle]);

          dataset.data.push(rowDataObject[chartName]);
          dataset.backgroundColor.push(rowDataObject[serieColor]);
        }
        data.datasets.push(dataset);

        createChart(data);
      }, refreshTime);
    };

    let myChart;
    const createChart = async (data) => {
      if (!myChart)
        myChart = new Chart(ctx, {
          type: props.attributes.viewMode === "piechart" ? "pie" : "doughnut",
          data: data,
          options: {
            cutoutPercentage: props.attributes.viewMode === "piechart" ? 0 : 70,
            // animation: { duration: 1000 },
          },
        });
      else {
        if (myChart.data != data) {
          myChart.data = data;
          myChart.update();
        }
      }
    };

    /**
     * Group Id
     * @type {string}
     */
    const groupId = inject("groupId");

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");

    // Get chart element
    nextTick(() => {
      ctx = document.getElementById(
        `chart-${breadcumbId}-${props.attributes.name}${objectIndex}`
      );
      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name);
      xoneControl.refresh = refresh;
      xoneView.addControl(xoneControl);
      watchEffect(async () => {
        if (contents.value || groupId !== activeGroup.value) return;
        refresh();
      });
    });

    // Clear contents
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    return { breadcumbId, objectIndex };
  },
};
</script>

<style scoped>
canvas {
  position: relative;
  width: var(--contents-width);
  height: var(--contents-height);
  max-height: var(--contents-max-height);
}
</style>