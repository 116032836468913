<template>
  <!-- is link? -> <a href></a> -->
  <a
    v-if="attributes.viewMode === 'link' && attributes.href"
    :href="attributes.href"
    target="_blank"
    ><slot></slot
  ></a>
  <!-- else -->
  <slot v-else></slot>
</template>

<script>
export default {
  name: "Column",
  props: {
    attributes: { type: Object, required: true },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}
</style>