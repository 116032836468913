import { nextTick } from "@vue/runtime-core";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import AppDataHandler from "./AppDataHandler";
import { getView } from "./XoneViewsHandler";
import xoneAppHandler from "./XoneAppHandler";

/**
 * _doLogin
 * @type {function():Promise<void>}
 */
let _doLogin;

/**
 * setDoLogin
 */
export const setDoLogin = (
  /** @type {function():Promise<void>} */ callback
) => {
  _doLogin = callback;
};

/**
 * _openUrlCallback
 * @type {function}
 */
let _openUrlCallback;

/**
 * _msgBoxCallback
 * @type {function}
 */
let _msgBoxCallback;

/**
 * _customMsgBoxCallback
 * @type {function}
 */
let _customMsgBoxCallback;

/**
 * _hideLoaderCallback
 * @type {function}
 */
let _hideLoaderCallback;

/**
 * _showGroupCallback
 * @type {function}
 */
let _showGroupCallback;

/**
 * _showLoaderCallback
 * @type {function}
 */
let _showLoaderCallback;

/**
 * _showToastCallback
 * @type {function}
 */
let _showToastCallback;

/**
 * _showSnackbarCallback
 * @type {function}
 */
let _showSnackbarCallback;

/**
 * _startCameraCallback
 * @type {function}
 */
let _startCameraCallback;

/**
 * _xoneDataObject
 * @type {XoneDataObject}
 */
let _xoneDataObject;

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} openUrlCallback
 */
export const setOpenUrlCallback = (openUrlCallback) => {
  _openUrlCallback = openUrlCallback;
};

/**
 * Add callback function msgBox from current msgbox component
 * @param {function} msgBoxCallback
 */
export const setMsgBoxCallback = (msgBoxCallback) => {
  _msgBoxCallback = msgBoxCallback;
};

/**
 * Add callback function msgBox from current msgbox component
 * @param {function} customMsgBoxCallback
 */
export const setCustomMsgBoxCallback = (customMsgBoxCallback) => {
  _customMsgBoxCallback = customMsgBoxCallback;
};

/**
 * Add callback function hideLoader from current loader component
 * @param {function} hideLoaderCallback
 */
export const setHideLoaderCallback = (hideLoaderCallback) => {
  _hideLoaderCallback = hideLoaderCallback;
};

/**
 * Add callback function showGroup from current coll component
 * @param {function} showGroupCallback
 */
export const setShowGroupCallback = (showGroupCallback) => {
  _showGroupCallback = showGroupCallback;
};

/**
 * Add callback function showLoader from Loader component
 * @param {function} showLoaderCallback
 */
export const setShowLoaderCallback = (showLoaderCallback) => {
  _showLoaderCallback = showLoaderCallback;
};

/**
 * Add callback function showToast from Toast component
 * @param {function} showToastCallback
 */
export const setShowToastCallback = (showToastCallback) => {
  _showToastCallback = showToastCallback;
};

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} showSnackbarCallback
 */
export const setShowSnackbarCallback = (showSnackbarCallback) => {
  _showSnackbarCallback = showSnackbarCallback;
};

/**
 * Add callback function showSnackbar from SnackBar component
 * @param {function} startCameraCallback
 */
export const setStartCameraCallback = (startCameraCallback) => {
  _startCameraCallback = startCameraCallback;
};

/**
 * setXoneDataObject
 * @param {XoneDataObject} xoneDataObject
 */
export const setXoneDataObject = (xoneDataObject) => {
  _xoneDataObject = xoneDataObject;
};

/**
 * @type {Object}
 */
let _lastGpsPosition;

/**
 * @type {Map<string, Notification>}
 */
const _mapNotifications = new Map();

/**
 * XoneUI class
 */
class XoneUI {
  /**
   * @type {XoneUI}
   */
  static _instance;

  constructor() {
    if (XoneUI._instance) return XoneUI._instance;
    XoneUI._instance = this;
  }

  addCalendarItem(title, description, location, dtStart, dtEnd) { }
  askUserForGPSPermission() { } // Ignorar
  canMakePhoneCall() { } // Ignorar
  captureImage(PropName, CaptureObjectName) { }
  checkGPSStatus() { }

  /**
   * clearDrawing
   * @param {string} PropName
   */
  clearDrawing(PropName) {
    // xoneView
    const xoneView = getView(_xoneDataObject);
    if (!xoneView) return;

    xoneView[PropName]?.clearDrawing();
  }

  createShortcut() { } // Ignorar
  deleteShortcut() { } // Ignorar

  /**
   * dismissNotification
   * @param {string} id
   */
  dismissNotification(id) {
    id = id.toString();

    if (!_mapNotifications.has(id)) return;

    const notification = _mapNotifications.get(id);
    notification.close();

    _mapNotifications.delete(id);
  }

  drawMapRoute(
    PropName,
    DestinationLatitude,
    DestinationLongitude,
    SourceLatitude,
    SourceLongitude,
    Mode,
    StrokeColor
  ) { }
  endPrint() { }
  ensureVisible(Prop, type) { }
  executeActionAfterDelay(NodeName, delay) { }

  /**
   * Go to previus object in stack
   */
  exit() {
    AppDataHandler.deleteLastBreadcumb();
    history.back();
  }

  /**
   * Exit  App
   */
  exitApp() {
    AppDataHandler.clearBreadcumbs();
    history.back();
    _doLogin();
  }

  /**
   * getLastKnownLocation
   */
  getLastKnownLocation() {
    const { latitude, longitude } = _lastGpsPosition?.coords;
    return { latitude, longitude };
  }

  /**
   * getLastKnownLocationAccuracy
   */
  getLastKnownLocationAccuracy() {
    const { accuracy } = _lastGpsPosition?.coords;
    return accuracy;
  }

  /**
   * getLastKnownLocationAltitude
   */
  getLastKnownLocationAltitude() {
    const { altitude } = _lastGpsPosition?.coords;
    return altitude;
  }

  /**
   * getLastKnownLocationBearing
   */
  getLastKnownLocationBearing() {
    const { heading } = _lastGpsPosition?.coords;
    return heading;
  }

  /**
   * getLastKnownLocationDateTime
   */
  getLastKnownLocationDateTime() {
    return _lastGpsPosition?.timestamp
      ? new Date(_lastGpsPosition.timestamp).toString()
      : null;
  }

  /**
   * getLastKnownLocationLatitude
   */
  getLastKnownLocationLatitude() {
    const { latitude } = _lastGpsPosition?.coords;
    return latitude;
  }

  /**
   * getLastKnownLocationLongitude
   */
  getLastKnownLocationLongitude() {
    const { longitude } = _lastGpsPosition?.coords;
    return longitude;
  }

  /**
   * getLastKnownLocationProvider
   */
  getLastKnownLocationProvider() {
    return _lastGpsPosition ? "geolocation" : "";
  }

  /**
   * getLastKnownLocationSpeed
   */
  getLastKnownLocationSpeed() {
    const { speed } = _lastGpsPosition?.coords;
    return speed;
  }
  getMaxSoundVolumen() { }
  getSoundVolumen() { }

  /**
   * getView
   * @param {XoneDataObject} DataObject
   */
  getView(DataObject) {
    return getView(DataObject || _xoneDataObject);
  }

  /**
   * Hide Group
   * @param {string|number} groupId
   */
  hideGroup(groupId) {
    _showGroupCallback
      ? _showGroupCallback(groupId.toString())
      : console.error("hideGroup is not defined", groupId);
  }

  /**
   * Hide loader spinner
   */
  async hideLoader() {
    _hideLoaderCallback
      ? await _hideLoaderCallback()
      : console.error("hideLoader not defined");
  }

  hideNavigationDrawer() { }
  hideSoftwareKeyboard() { }
  hideWaitDialog() { }
  injectJavascript(WebViewPropName, ScriptText) { }
  isApplicationInstalled(packageName) { }
  isOnCall() { }
  isSuperuserAvailable() { }
  isTaskKillerInstalled() { }
  isWifiConnected() { }
  isWifiEnabled() { }
  launchApp(PackageName, ExtrasParam) { }
  launchApplication(PackageName, ExtrasParam) { }

  /**
   * Launch entry point
   */
  async launchEntryPoint() {
    await this.showLoader();
    // Clear current DataObjects and breadcumbs
    AppDataHandler.clearBreadcumbs();

    /**
     * webLayout
     * @type {string}
     */
    const webLayout = xoneAppHandler.getWebLayout();

    if (webLayout) {
      // Push Web Layout
      await AppDataHandler.addNewXoneDataObject(webLayout, "WebLayout", true);
      await nextTick();
    }

    /**
     * entryPoint
     * @type {string}
     */
    const entryPoint = AppDataHandler.getAppData().getEntryPointCollection(
      AppDataHandler.getAppData().getCurrentVisualConditions()
    );

    // Push Entry Point
    await AppDataHandler.addNewXoneDataObject(
      this.entryPointFake || entryPoint,
      "EntryPoint"
    );

    this.hideLoader();
  }

  lineFeed(Lines) { }
  lockGroup(groupId) { }
  makePhoneCall(PhoneNumber) { }

  /**
   * Show MsgBox
   * @param {string|XoneDataObject} message
   * @param {string} [title]
   * @param {number} [flags]
   * @returns {Promise<number>}
   */
  async msgBox(message, title = "", flags = 0) {
    let res = 0;

    if (message instanceof XoneDataObject) {
      if (_customMsgBoxCallback) return await _customMsgBoxCallback(message);
      else console.error("Method not implemented");
      return res;
    }

    if (_msgBoxCallback) {
      res = await _msgBoxCallback(message, title, flags);
    } else {
      console.error("msgBox not defined");
    }

    return res;
  }

  async msgBoxWithSound(Message, Title, type, Sound, Vibrate, NumberRepeat) {
    this.playSoundAndVibrate(Sound, Vibrate, NumberRepeat);
    return await this.msgBox(Message, Title, type);
  }

  /**
   * Open new Edit View and push it in Breadcumbs stack
   * @param {XoneDataObject|string} param
   */
  async openEditView(param) {
    await this.showLoader();
    // string: create XoneDataObject and push it into stack
    if (typeof param === "string") {
      await AppDataHandler.addNewXoneDataObject(param);
      this.hideLoader();
    }
    // XoneDataObject: push it into stack
    else if (param instanceof XoneDataObject) {
      /**
       * xoneDataObject
       * @type {XoneDataObject}
       */
      const xoneDataObject = param;
      AppDataHandler.pushXoneDataObject(xoneDataObject);
      this.hideLoader();
    }
    // Bad call
    else {
      this.hideLoader();
      console.error("Bad call openEditView with param ", param);
    }
  }

  /**
   * Open new Edit View and push it in Breadcumbs stack then 1
   * @param {XoneDataObject|string} param
   */
  async openEditViewAndExit(param) {
    AppDataHandler.clearBreadcumbs(true);
    await this.openEditView(param);
  }

  /**
   * openFile
   * @param {string} filePath
   */
  openFile(filePath) {
    filePath = filePath.replace("/source/", "/"); // TODO: el filePath viene mal concateado, hay que quitar el source
    const element = document.createElement("a");
    element.setAttribute("href", filePath);
    element.setAttribute("target", "_blank");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  openMenu(Name, Mask, Mode) { }

  /**
   * Open Url
   * @param {string} Url
   * @returns {void}
   */
  openUrl(Url) {
    if (_openUrlCallback) _openUrlCallback(Url);
    else console.error("Method openUrl not implemented");
  }

  /**
   * pickFile
   * @param {string} PropName
   * @param {string} Extensions
   * @param {*} PictureOnly
   * @param {string} Path
   * @param {*} Flag
   */
  pickFile(PropName, Extensions, PictureOnly, Path, Flag) {
    /** inputElement
     * @type {HTMLInputElement}
     */
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.style.display = "none";

    if (Extensions) inputElement.accept = Extensions;

    inputElement.onchange = (/** @type {*} */ e) => {
      const file = e.target.files[0];
      console.log(file);
    };

    document.body.appendChild(inputElement);
    inputElement.click();
    document.body.removeChild(inputElement);
  }

  /**
   * playSound
   * @param {string} sound
   * @returns {Promise<void>}
   */
  playSound(sound) {
    return this.playSoundAndVibrate(sound);
  }

  /**
   * playSoundAndVibrate
   * @param {string} Sounds
   * @param {*} vibrate
   * @param {*} NumberRepeat
   * @param {*} ContinuePlaying
   * @returns {Promise<void>}
   */
  playSoundAndVibrate(Sounds, vibrate, NumberRepeat, ContinuePlaying) {
    return new Audio(`files/${Sounds}`).play();
  }

  playSoundVolumen(Number) { }
  print(Data) { }
  printBIDI(Size, Level, Data) { }
  printBarcode(Type, Data, Width, Height) { }
  printCommand(Data) { }
  printImage(Path, Width, Height, Align, Dither) { }
  printLine(Data) { }
  printPDF(Path, PageNumber) { }

  /**
   * quitApp
   */
  quitApp() {
    this.exitApp();
  }

  /**
   * recognizeSpeech
   * @param {XoneDataObject|Object} DataObject
   * @param {string} [PropName]
   */
  recognizeSpeech(DataObject, PropName) {
    if (!webkitSpeechRecognition && !SpeechRecognition)
      return console.error(
        "recognizeSpeech not supported. Use chrome browser."
      );
    let isXoneDataObject = false,
      isObject = false;
    // Is XoneDataObject
    if (DataObject instanceof XoneDataObject) {
      isXoneDataObject = true;
    }
    // Is Object with callback
    else if (typeof DataObject === "object" && DataObject.onRecognize) {
      isObject = true;
    } else return console.error("recognizeSpeech bad call");
    // Instance recognition
    /**
     * recognition
     * @type {SpeechRecognition}
     */
    const recognition = new (webkitSpeechRecognition || SpeechRecognition)();
    // Params
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
    // On result
    recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1][0].transcript;
      if (isObject) DataObject.onRecognize(result);
      if (isXoneDataObject) DataObject[PropName] = result;
    };
    // On speech end
    recognition.onspeechend = () => recognition.stop();
    // On stop
    recognition.onerror = (event) => {
      recognition.stop();
      if (isObject && DataObject.onError) DataObject.onError(event.error);
      console.log(
        `%c SpeechRecognition error: '${event.error}' %c Feature allowed in %c Chrome %c browser `,
        "color: red",
        "background: #4CABD5; color: white",
        "background: #1F3C6E; color: white",
        "background: #4CABD5; color: white"
      );
    };
    // start recognition
    recognition.start();
  }

  /**
   * Refresh controls
   * @param {Array} Props
   */
  refresh(...Props) {
    const xoneView = getView(_xoneDataObject);
    if (!xoneView) return;
    const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
    props.forEach((/** @type {string} */ e) => xoneView[e]?.refresh());
    if (Props.length === 0) xoneView.refresh();
  }
  /**
   * refreshAll
   */
  refreshAll() {
    this.refresh();
  }

  /**
   * refreshContentRow
   * @param {string} ContentName
   * @param {number} Row
   */
  refreshContentRow(ContentName, Row) {
    const xoneView = getView(_xoneDataObject);
    if (!xoneView) return;
    xoneView[ContentName]?.refreshRow(Row);
  }

  /**
   * refreshContentSelectedRow
   * @param {string} ContentName
   */
  refreshContentSelectedRow(ContentName) {
    const xoneView = getView(_xoneDataObject);
    if (!xoneView) return;
    xoneView[ContentName]?.refreshSelectedRow();
  }

  /**
   * refreshValue
   * @param {string} Props
   */
  refreshValue(Props) {
    const xoneView = getView(_xoneDataObject);
    if (!xoneView) return;
    xoneView[Props]?.refresh();
  }

  relayout() {
    this.refresh();
  }
  restartApp() { }
  returnToForeground() { }
  returnToMainMenu() { }
  saveDrawing(PropName, FileName) { }
  sendMail(To, Cc, Subject, Message, Attachments) { }
  sendSMS(Phone, Text) { }
  setFeedMode(FeedMode) { }
  setLanguage(Language) { }
  setMaxWaitDialog(Max) { }
  setNotificationLed(LedColor, LedOn, LedOff) { }
  setSelection(Prop, Position) { }
  shareData(Subject, Text, image) { }
  showConsoleReplica() { }
  showDatePicker(JSONObject) { }

  /**
   * Show Group
   * @param {string|number} groupId
   * @param {*} [animationIn]
   * @param {*} [animationInDuration]
   * @param {*} [animationOut]
   * @param {*} [animationOutDuration]
   */
  showGroup(
    groupId,
    animationIn,
    animationInDuration,
    animationOut,
    animationOutDuration
  ) {
    _showGroupCallback
      ? _showGroupCallback(groupId.toString())
      : console.error("showGroup is  not defined", groupId);
  }

  /**
   * Show Spinner loader
   */
  async showLoader() {
    _showLoaderCallback
      ? await _showLoaderCallback()
      : console.error("showLoader is not defined");
  }

  showNavigationDrawer(Orientation) { }

  /**
   * showNotification
   * @param {string} id
   * @param {string} Title
   * @param {string} Text
   * @param {*} TextStatusBar
   * @param {*} DataObject
   * @param {*} NodeName
   */
  showNotification(id, Title, Text, TextStatusBar, DataObject, NodeName) {
    if (!("Notification" in window))
      return console.error("shotNotification not supporter in your browse");

    /**
     * donotification
     */
    const doNotification = () => {
      if (Notification.permission !== "granted")
        return console.error("Not permission granted to show notifications");

      id = id.toString();

      // Dismiss if notification exists
      this.dismissNotification(id);

      // Create  new Notification
      const notification = new Notification(Title, {
        icon: "assets/manifest/72x72.png",
        body: Text,
      });

      // Add click event
      notification.onclick = () => {
        if (DataObject) this.openEditView(DataObject);
        this.dismissNotification(id);
      };

      // Set notification to notifications map list
      _mapNotifications.set(id, notification);
    };

    // Check permission
    if (Notification.permission !== "granted")
      Notification.requestPermission().then(() => doNotification());
    // doNotification
    else doNotification();
  }

  /**
   * Show snackbar
   * @param {string|Object} params
   */
  showSnackbar(params) {
    _showSnackbarCallback
      ? _showSnackbarCallback(params)
      : console.error("showSnackbar is  not defined", params);
  }

  showSoftwareKeyboard() { }

  /**
   * Show Toast
   * @param {string} message
   */
  showToast(message) {
    _showToastCallback
      ? _showToastCallback(message)
      : console.error("showToast is not defined", message);
  }

  showWaitDialog(Text) { }
  signDataObject(Data, Mask) { }

  /**
   * sleep
   * @param {number} Seconds
   */
  async sleep(Seconds) {
    await new Promise((resolve) =>
      setTimeout(() => resolve(), Number(Seconds) * 1000)
    );
  }

  /**
   * speak
   * @param {string} Language
   * @param {string} Text
   */
  speak(Language, Text) {
    if (!speechSynthesis)
      return console.error("speak not supported in your browser");
    const speechSynthesisUtterance = new SpeechSynthesisUtterance(Text);
    if (Language) speechSynthesisUtterance.lang = Language;
    speechSynthesis.speak(speechSynthesisUtterance);
  }

  /**
   * speakText
   * @param {string} Text
   */
  speakText(Text) {
    this.speak(null, Text);
  }

  startAudioRecord(NodeName, Prop, TimeOut) { }

  /**
   * startCamera
   * @param {string} PropName
   * @param {string} type
   */
  async startCamera(PropName, type = "photo") {
    let value;
    if (_startCameraCallback) value = await _startCameraCallback(type);
  }

  /**
   * startGps
   * @param {*} JSONObject
   * @param {*} Interval
   * @param {*} Flags
   */
  async startGps(JSONObject, Interval = 30000, Flags) {
    if (!navigator.geolocation) return console.error("Geolocation not allowed");

    this.stopGps();

    const pos = await new Promise((resolve) =>
      navigator.geolocation.getCurrentPosition(
        (res) => resolve(res), // Error function
        () => console.error("Could not get location"),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      )
    );

    _lastGpsPosition = pos;

    console.log(` %c LOCATION `, "background-color: BLUE; color: white;", pos);

    this._startGpsTimeout = setTimeout(
      () => this.startGps(JSONObject, Interval, Flags),
      Interval
    );
  }

  /**
   * startGpsV1
   */
  async startGpsV1() {
    await this.startGps();
  }

  /**
   * startGpsV2
   * @param {*} JSONObject
   * @param {*} Interval
   * @param {*} Flags
   */
  async startGpsV2(JSONObject, Interval, Flags) {
    await this.startGps();
  }

  startKioskMode() { }
  startPrint(PrinterType) { }
  startReplica() { }
  startScanner(NativeObject, Codes, Target) { }
  startSignature(Prop, Width, Height, BackgroundImage, ScreenOrientation) { }
  startWifi() { }
  stopAudioRecord() { }
  stopGps() {
    if (this._startGpsTimeout) clearTimeout(this._startGpsTimeout);
  }
  stopGpsV1() { }
  stopGpsV2() { }
  stopKioskMode() { }
  stopPlaySoundAndVibrate() { }
  stopReplica() { }
  stopWifi() { }

  /**
   * Take photo
   * @param {*} FileName
   * @param {*} Width
   * @param {*} Height
   */
  takePhoto(FileName, Width, Height) {
    if (_startCameraCallback) _startCameraCallback();
  }

  /**
   * Toogle Group
   * @param {string|number} groupId
   */
  toggleGroup(groupId) {
    _showGroupCallback
      ? _showGroupCallback(groupId.toString(), true)
      : console.error("toggleGroup is not defined", groupId);
  }

  uninstallApplication(packageName) { }
  unlockGroup(groupId) { }
  updateWaitDialog(message, value) { }
  useLastPrinter(True) { }
  vibrate() { }
  writeString() { }
}

const xoneUI = new XoneUI();

export default xoneUI;
