<template>
  <div
    v-if="!attributes.imgChecked && !attributes.imgUnchecked"
    :style="{
      padding: '0',
      height: 'var(--label-height)',
      // Align
      alignItems: attributes.labelAlign,
      justifyContent: attributes.labelAlign,
    }"
  >
    <input
      v-if="!attributes.isRadio"
      ref="checkboxElement"
      :disabled="isDisabled"
      :checked="xoneDataObject.model[attributes.name] === 1"
      @change="onCheckboxCheckedChange()"
      type="checkbox"
    />

    <input
      v-else
      ref="radioElement"
      :disabled="isDisabled"
      type="radio"
      :name="`${attributes.radioGroup}-${breadcumbId}${objectIndex}`"
      :prop="attributes.name"
      :checked="xoneDataObject.model[attributes.name] === 1"
      @change="onRadioCheckedChange()"
    />
  </div>
  <button
    v-else
    :disabled="isDisabled"
    @click="onCheckboxCheckedChange()"
    :style="{
      // Size
      width: imageSize.width
        ? `${imageSize.width}px`
        : (attributes.width !== undefined && '100%') || attributes.fieldSize,
      height: imageSize.height
        ? `${imageSize.height}px`
        : (controlHeight && `${controlHeight - 11}px`) || '30px',
      // Background Image
      backgroundImage: `url(${
        isDisabled || attributes.locked
          ? xoneDataObject.model[attributes.name] == 1
            ? backgroundImageCheckedDisabled || backgroundImageChecked
            : backgroundImageUncheckedDisabled || backgroundImageUnchecked
          : xoneDataObject.model[attributes.name] == 1
          ? backgroundImageChecked
          : backgroundImageUnchecked
      })`,
      // Disableedit / Locked appearance
      opacity:
        changeOpacity &&
        attributes.disableEdit &&
        attributes.disableEdit !== '' &&
        isDisabled
          ? 0.4
          : 1,
    }"
  ></button>
</template>

<script>
import {
  Ref,
  ComputedRef,
  PropType,
  computed,
  ref,
  reactive,
  inject,
  onMounted,
} from "vue";

import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { getImagePath } from "../../../composables/helperFunctions/ImageHelper";

export default {
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },

    /** attributes
     @type {PropType<PropAttributes>} 
     */
    attributes: { type: Object, required: true },

    isDisabled: { type: Boolean, required: true },

    controlWidth: { type: Number, default: 0 },

    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /** @type {Ref<HTMLInputElement>} */
    const checkboxElement = ref();

    /** @type {Ref<HTMLInputElement>} */
    const radioElement = ref();

    const breadcumbId = inject("breadcumbId");

    /**
     * Get editInRow="false" if prop is in contents
     * @type {import('../../../composables/AppDataHandler').Objectinfo}
     */
    const objectInfo = inject("objectInfo");

    const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

    /**
     * on value change
     */
    const onChange = async () => {
      try {
        if (objectInfo?.editInRow && objectInfo.autosave) {
          await props.xoneDataObject.save();
        }
      } catch (ex) {
        xoneUI.showSnackbar({
          text: ex,
          color: "red",
        });
      }
    };

    const onCheckboxCheckedChange = () => {
      props.xoneDataObject.changeModelValue(
        props.attributes.name,
        props.xoneDataObject.model[props.attributes.name] === 0 ||
          !props.xoneDataObject.model[props.attributes.name]
          ? 1
          : 0
      );
      onChange();
    };

    const onRadioCheckedChange = () => {
      props.xoneDataObject.changeModelValue(
        props.attributes.name,
        radioElement.value.checked ? 1 : 0
      );
      document
        .querySelectorAll(
          `[name='${props.attributes.radioGroup}-${breadcumbId}${objectIndex}']`
        )
        .forEach(
          /** @type {HTMLInputElement} */ (e) => {
            if (e === radioElement.value) return;
            props.xoneDataObject.changeModelValue(e.getAttribute("prop"), 0);
          }
        );
      onChange();
    };

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    const imageSize = reactive({
      width: computed(() =>
        xoneAttributesHandler.getScaledSize(
          props.attributes.imgWidth,
          props.containerWidth,
          widthFactor.value
        )
      ),
      height: computed(() =>
        xoneAttributesHandler.getScaledSize(
          props.attributes.imgHeight, // offset height
          props.containerHeight,
          heightFactor.value
        )
      ),
    });

    const backgroundImageChecked = ref();
    const backgroundImageUnchecked = ref();
    const backgroundImageCheckedDisabled = ref();
    const backgroundImageUncheckedDisabled = ref();

    onMounted(async () => {
      backgroundImageChecked.value = await getImagePath(
        props.attributes.imgChecked,
        "icons"
      );
      backgroundImageUnchecked.value = await getImagePath(
        props.attributes.imgUnchecked,
        "icons"
      );
      backgroundImageCheckedDisabled.value = await getImagePath(
        props.attributes.imgCheckedDisabled,
        "icons"
      );
      backgroundImageUncheckedDisabled.value = await getImagePath(
        props.attributes.imgUncheckedDisabled,
        "icons"
      );

      if (
        backgroundImageCheckedDisabled.value ||
        backgroundImageUncheckedDisabled.value
      )
        changeOpacity.value = false;
    });

    const changeOpacity = ref(true);

    return {
      checkboxElement,
      radioElement,
      breadcumbId,
      objectIndex,
      imageSize,
      backgroundImageChecked,
      backgroundImageUnchecked,
      onChange,
      onCheckboxCheckedChange,
      onRadioCheckedChange,
      backgroundImageCheckedDisabled,
      backgroundImageUncheckedDisabled,
      changeOpacity,
    };
  },
};
</script>

<style scoped>
input[type="checkbox"],
input[type="radio"] {
  margin: 5px 0;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
  align-self: flex-start;
  cursor: pointer;
}

button {
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  background-color: transparent;
  animation: fadeIn 0.3s;
  transition: all 0.3s;
}
</style>