<template>
  <div
    v-if="containerWidth && containerHeight"
    class="xone-app"
    :style="{
      '--app-width': `${containerWidth}px`,
      '--app-height': `${containerHeight}px`,
    }"
  >
    <!-- Layout breadcumb -->
    <div class="xone-layout" v-if="webLayoutBreadcumb">
      <Coll :breadcumb="webLayoutBreadcumb" :isLayout="true"></Coll>
    </div>

    <!-- Router -->
    <router-view v-else />

    <!-- UI Components -->
    <SnackBar />
    <Toast />
    <Loader />
    <MsgBox />
    <Camera />
    <Url />
    <Offline />

    <!-- Drag & Drop -->
    <SelectedControl />
    <OverControl />
    <DragControl />
  </div>
</template>

<script>
// Vue
import {
  provide,
  ref,
  Ref,
  ComputedRef,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
// Components
import Coll from "@/components/Coll";
import Toast from "./components/uiComponents/Toast.vue";
import SnackBar from "./components/uiComponents/SnackBar.vue";
import Loader from "./components/uiComponents/Loader.vue";
import MsgBox from "./components/uiComponents/MsgBox.vue";
import Camera from "./components/uiComponents/Camera.vue";
import Url from "./components/uiComponents/Url.vue";
import Offline from "./components/uiComponents/Offline.vue";
import SelectedControl from "./components/dragAndDrop/SelectedControl";
import OverControl from "./components/dragAndDrop/OverControl";
import DragControl from "./components/dragAndDrop/DragControl";
// Composables
import { XoneApplication } from "./composables/appData/core/XoneApplication";
import { FileManagerOnline } from "./composables/appData/Manager/FileManager";
import AppDataHandler, { Breadcumb } from "./composables/AppDataHandler";
import xoneAppHandler from "./composables/XoneAppHandler";
import { useRoute, useRouter } from "vue-router";
import xoneUI, { setDoLogin } from "./composables/XoneUI";

export default {
  components: {
    Coll,
    Toast,
    SnackBar,
    Loader,
    MsgBox,
    Camera,
    Url,
    Offline,
    SelectedControl,
    OverControl,
    DragControl,
  },
  setup() {
    const version = "";

    console.log(
      `%c b y %c X %c >O n e %c ${version}`,
      "background: whitesmoke; color: #1F3C6E; font-weight: bold; display: block; font-size: 17px;",
      "background: #4CABD5   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
      "background: #1F3C6E   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
      "color: #1F3C6E"
    );

    const router = useRouter();
    const route = useRoute();

    /**
     * XOne Web Layout
     * @type {ComputedRef<Breadcumb>}
     */
    const webLayoutBreadcumb = computed(() => {
      const breadcumb = AppDataHandler.getBreadcumbs().filter(
        (e) => e.isWebLayout
      );
      return breadcumb.length !== 0 ? breadcumb[0] : null;
    });

    /**
     * routeBreadcumbs: router object Stack
     * @type {ComputedRef<Breadcumb[]>}
     */
    const routeBreadcumbs = computed(() =>
      AppDataHandler.getBreadcumbs().filter(
        (e) => !e.isWebLayout && !e.isMsgbox
      )
    );
    provide("routeBreadcumbs", routeBreadcumbs);

    /**
     * lastBreadcumb
     * @type {ComputedRef<Breadcumb>}
     */
    const lastBreadcumb = computed(
      () =>
        routeBreadcumbs.value.length !== 0 &&
        routeBreadcumbs.value[routeBreadcumbs.value.length - 1]
    );

    // Provide lastBreadcumb to child components
    provide("lastBreadcumb", lastBreadcumb);

    //
    // Manage Window Size / Scale

    /**
     * appWidth
     * @type {Ref<number>}
     */
    let appWidth = ref();

    /**
     * appHeight
     * @type {Ref<number>}
     */
    let appHeight = ref();

    provide("appSize", { appWidth, appHeight });

    /**
     * containerWidth
     * @type {Ref<number>}
     */
    let containerWidth = ref(null);

    /**
     * containerHeight
     * @type {Ref<number>}
     */
    let containerHeight = ref(null);

    // Check if an input is focused in order to handle keyboard in mobile devices
    const isInputFocused = ref(false);
    provide("isInputFocused", isInputFocused);

    watch(
      () => isInputFocused.value,
      (newValue) => {
        if (!newValue) setTimeout(() => onResize(), 250);
      }
    );

    let resizeTimeout = 25;
    let allowZoom = false;
    /**
     * on window resize
     */
    const onResize = () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        const zoom = allowZoom ? window.devicePixelRatio : 1;
        if (isInputFocused.value) return;
        containerWidth.value =
          (appWidth.value && window.innerWidth > appWidth.value
            ? appWidth.value
            : window.innerWidth) *
            zoom -
          2;
        containerHeight.value =
          (appHeight.value && window.innerHeight > appHeight.value
            ? appHeight.value
            : window.innerHeight) *
            zoom -
          2;
        // Send resolution macros
        xoneAppHandler &&
          xoneAppHandler.setVisualConditionsMacros(
            containerWidth.value,
            containerHeight.value
          );

        sizeConditions.value = xoneAppHandler.sizeConditions;
        orientation.value = xoneAppHandler.orientation;
      }, 50);
    };

    onMounted(() => window.addEventListener("resize", onResize));
    onUnmounted(() => window.removeEventListener("resize", onResize));

    provide("containerSize", { containerWidth, containerHeight });

    /**
     * Size Conditions
     * @type {Ref<string>}
     */
    const sizeConditions = ref("");
    provide("sizeConditions", sizeConditions);

    /**
     * Orientation
     * @type {Ref<string>}
     */
    const orientation = ref("");
    provide("orientation", orientation);

    //
    // Scale Factor

    /**
     * widthFactor
     * @type {ComputedRef<number>}
     */
    const widthFactor = computed(() =>
      appWidth.value ? containerWidth.value / appWidth.value : 1
    );

    /**
     * heightFactor
     * @type {ComputedRef<number>}
     */
    const heightFactor = computed(() =>
      appHeight.value ? containerHeight.value / appHeight.value : 1
    );
    // provide scaleFactor to child components
    provide("scaleFactor", { widthFactor, heightFactor });

    // displayScreenThresholds
    const displayScreenThresholds = ref(false);
    provide("displayScreenThresholds", displayScreenThresholds);

    const showBreadcumbs = ref(false);
    provide("showBreadcumbs", showBreadcumbs);

    /**
     * Init appData
     */
    onMounted(() => {
      const appData = new XoneApplication();

      // provide appData
      AppDataHandler.setAppData(appData);

      // set UI
      appData.setUserInterface(xoneUI);

      // Get App Configuration File and Script_Wrapper
      const getConfig = Function(
        "cbF",
        "(async()=>{try{const c=await import('../configuration.js');const{__SCRIPT_WRAPPERASYNC}=await import('../source/__SCRIPT_WRAPPER__.js');cbF({...c.default,sWA:__SCRIPT_WRAPPERASYNC});}catch(ex){console.error('Error loading configuration.js and __SCRIPT_WRAPPER__.js files',ex);}})()"
      );

      getConfig(async (config) => {
        if (config.title) document.title = config.title;
        if (config.displayScreenThresholds)
          displayScreenThresholds.value = true;
        if (config.showBreadcumbs) showBreadcumbs.value = config.showBreadcumbs;

        if (config.debugging) window.debugging = true;

        allowZoom = config.allowZoom;
        //
        // Init App
        await appData.IniciarApp(
          `${location.origin}/${config.sourcePath}`,
          config.sWA,
          new FileManagerOnline(),
          `Data Source=${config.apiUrl};xoneuser=${config.clientId};xonepass=${
            config.clientSecret
          };ids=${config.idsUrl || "https://ids.xonedev.cloud/connect/token"}`
        );

        xoneAppHandler.setAppData(appData);

        // Set app width / height
        const appSize = xoneAppHandler.getAppSize();
        appWidth.value = appSize?.width;
        appHeight.value = appSize?.height;
        onResize();

        // set macros
        xoneAppHandler.setInmutableMacros(appData);

        autoLogon = xoneAppHandler.getAutoLogon(); // TODO: crear autologon
        doLogin(autoLogon);
      });
    });

    let autoLogon = false;

    const doLogin = async () => {
      const entryPointFake = (xoneUI.entryPointFake = route.params?.id);
      if (entryPointFake) {
        autoLogon = true;
        displayScreenThresholds.value = true;
        xoneUI.entryPointFake = entryPointFake;
      }
      if (autoLogon) {
        return AppDataHandler.getAppData().login({
          userName: "admin",
          password: "",
        });
      }
      // if (!loginColl) // TODO: crear login integrado
      //   return router.push({ name: "Login", params: { id: "integrated" } });

      await AppDataHandler.addNewXoneDataObject(
        AppDataHandler.getAppData().getLoginCollectionName(),
        "Login"
      );

      // Send setDoLogin to XoneUI
      setDoLogin(doLogin);

      const routeTo = { name: "Login" };

      if (route.params?.id) routeTo.params = { id: route.params.id };
      router.push(routeTo);
    };

    provide("loginFunction", doLogin);

    return {
      webLayoutBreadcumb,
      containerWidth,
      containerHeight,
    };
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.xone-app {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  overflow: visible;
  max-height: 100vh;
  max-width: 100vw;
}
.xone-navbar {
  flex-shrink: 1;
}

.xone-layout,
.xone-dataobjects {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
  width: var(--app-width);
  height: var(--app-height);
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
    overflow: hidden;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translate(75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideRight {
  from {
    transform: translate(-75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes right_in {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideLeft {
  from {
    transform: translate(75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes slideRight {
  from {
    transform: translate(-75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes slideDown {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes right_in {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Leaflet map routing */
.leaflet-routing-container {
  display: none;
}

/* Loader */

.xone-loader {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  grid-column: 1 / -1;
}

.xone-loader div:first-child {
  border-radius: 50%;
  border: 5px solid lightgray;
  border-left: 5px solid gray;
  width: 3vh;
  height: 3vh;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
}

.xone-dataobject {
  position: relative;
  flex-grow: 1;
  animation: loadEffect 0.3s;
  display: flex;
  flex-direction: column;
}

@keyframes loadEffect {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes loadEffect {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
