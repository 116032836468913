<template>
  <!-- Input Checkbox -->
  <Checkbox
    v-if="attributes.type === 'NC'"
    :attributes="attributes"
    :xoneDataObject="xoneDataObject"
    :isDisabled="
      attributes.locked ||
      isDisableEdit ||
      isLinked ||
      (objectInfo.isContents && !objectInfo.editInRow)
    "
    :controlWidth="controlWidth"
    :controlHeight="controlHeight"
  ></Checkbox>
  <!-- label -->
  <div
    v-if="attributes.labelWidth != 0 && !attributes.floatingTooltip"
    class="xone-input-label"
    :style="{
      // Size
      height: 'var(--label-height)',
      width: (attributes.type === 'TL' && '100%') || attributes.labelWidth,
      // Font
      fontSize: attributes.fontSize,
      // Background
      backgroundColor: attributes.bgColor,
      // Borders
      border: attributes.labelBox && `1px solid ${attributes.borderColor}`,
      // is CheckBox? -> Grow
      flexGrow: attributes.type === 'NC' && 1,
    }"
  >
    <label
      :style="{
        // Font
        color: attributes.foreColor,
        fontSize: attributes.fontSize,
        // Align
        alignItems: attributes.labelAlign,
        justifyContent: attributes.labelAlign,
      }"
      class="xone-title"
      :class="{
        noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
      }"
      >{{ attributes.title }}</label
    >
  </div>
  <!-- Input -->
  <div
    class="xone-input-container"
    v-if="attributes.type != 'TL' && attributes.type !== 'NC'"
  >
    <!-- 1 line -->
    <input
      v-if="!attributes.multiLine"
      ref="inputElement"
      required
      :disabled="
        attributes.locked ||
        isDisableEdit ||
        isLinked ||
        (objectInfo.isContents && !objectInfo.editInRow)
      "
      @input="onInput"
      :value="propValue"
      :type="inputType"
      :step="numberStep"
      :maxlength="(attributes.fixedText && attributes.size) || null"
      :style="{
        // Size
        width:
          (attributes.width !== undefined && '100%') || attributes.fieldSize,
        height:
          (controlHeight && `${controlHeight - 11}px`) || attributes.lines,
        // Border
        borderRadius: attributes.borderCornerRadius,
        // Text
        textAlign: attributes.textAlign
          ? attributes.textAlign
          : attributes.align && attributes.align,
        fontSize: attributes.fontSize
          ? attributes.fontSize
          : attributes.textFontSize && attributes.textFontSize,
        // Is Linked? -> padding-right in order to show the search button
        paddingRight: isLinked && '35px',
      }"
      :class="{
        nobordertop: !attributes.borders.top,
        noborderright: !attributes.borders.right,
        noborderbottom: !attributes.borders.bottom,
        noborderleft: !attributes.borders.left,
        noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
      }"
      @change="onChange()"
      @focus="onFocusChanged(true)"
      @blur="onFocusChanged(false)"
      @keydown="onKeyPress"
    />
    <!-- 
        disabled: attributes.locked || isDisableEdit || isLinked, -->
    <!-- multi line -->
    <textarea
      ref="textAreaElement"
      v-else
      required
      :disabled="
        attributes.locked ||
        isDisableEdit ||
        isLinked ||
        (objectInfo.isContents && !objectInfo.editInRow)
      "
      @input="onInput"
      :value="propValue"
      :maxlength="(attributes.fixedText && attributes.size) || null"
      :style="{
        // Size
        width: (attributes.width && '99%') || attributes.fieldSize,
        height:
          (controlHeight && `${controlHeight - 11}px`) || attributes.lines,
        // Border
        borderRadius: attributes.borderCornerRadius,
        // Text
        textAlign: attributes.textAlign && attributes.textAlign,
        fontSize: attributes.textFontSize
          ? attributes.textFontSize
          : attributes.fontSize,
        // Is Linked? -> padding-right in order to show the search button
        paddingRight: isLinked && '35px',
      }"
      :class="{
        nobordertop: !attributes.borders.top,
        noborderright: !attributes.borders.right,
        noborderbottom: !attributes.borders.bottom,
        noborderleft: !attributes.borders.left,
        disabled: attributes.locked || isDisableEdit,
        noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
      }"
      @change="onChange()"
      @focus="onFocusChanged(true)"
      @blur="onFocusChanged(false)"
      @keydown="onKeyPress"
    />
    <!-- Linked search button -->
    <button
      v-if="isLinked && !(objectInfo.isContents && !objectInfo.editInRow)"
      ref="linkedSearchElement"
      class="xone-input-linked"
      :style="{ opacity: attributes.locked || isDisableEdit ? 0.5 : 1 }"
      :disabled="attributes.locked || isDisableEdit"
      @click="onLinkedSearchButtonClick"
    ></button>
    <!-- Linked search component -->
    <LinkedSearchInline
      v-if="isLinkedSearchVisible"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      @onHide="isLinkedSearchVisible = false"
      @onSelectedItem="onSelectedItem"
      :linkedSearchPosition="linkedSearchPosition"
    ></LinkedSearchInline>
    <!-- Tooltip -->
    <label
      v-if="attributes.tooltip !== '' && !attributes.locked && !isDisableEdit"
      :class="[
        'xone-tooltip',
        attributes.floatingTooltip ? 'float' : 'nofloat',
        attributes.floatingTooltip && propValue && isLinked
          ? 'linked-input-fill'
          : '',
      ]"
      :for="attributes.name"
      :style="{
        color: attributes.tooltipColor,
        textAlign: attributes.textAlign,
        '--floating-tooltip-transform': floatingTooltipTransform,
      }"
      >{{ attributes.tooltip }}</label
    >
  </div>
</template>

<script>
import {
  computed,
  inject,
  ref,
  Ref,
  ComputedRef,
  watch,
  PropType,
  onMounted,
  onUnmounted,
  nextTick,
} from "vue";

import {
  xoneAttributesHandler,
  PropAttributes,
} from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import xoneUI from "../../composables/XoneUI";
import LinkedSearchInline from "./LinkedSearchInline.vue";
import Checkbox from "./inputComponents/Checkbox.vue";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";

export default {
  components: { Checkbox, LinkedSearchInline },
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    /** attributes
     @type {PropType<PropAttributes>} 
     */
    attributes: { type: Object, required: true },
    isDisableEdit: { type: Boolean, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
    inputType: { type: String, default: "text" },
  },
  setup(props) {
    /**
     * Get editInRow="false" if prop is in contents
     * @type {import('../../composables/AppDataHandler').Objectinfo}
     */
    const objectInfo = inject("objectInfo");

    const editInRow = objectInfo?.editInRow;

    /**
     * Numeric step if property is float
     * @type {ComputedRef<string>}
     */
    const numberStep = computed(() => {
      if (
        props.attributes.type.length === 2 &&
        props.attributes.type[0] === "N"
      ) {
        const n = parseInt(props.attributes.type[1]);
        return isNaN(n) ? "" : `.${Array(n).join("0")}1`;
      }
      return "";
    });

    /**
     * Is linked property
     * @type {ComputedRef<boolean>}
     */
    const isLinked = computed(() => {
      return (
        props.attributes.linkedTo &&
        props.attributes.linkedTo !== "" &&
        props.attributes.linkedField &&
        props.attributes.linkedField !== ""
      );
    });

    // show / hide linked search modal
    const isLinkedSearchVisible = ref(false);

    //
    // item selected from the linked search
    const onSelectedItem = ({ mapValue, value }) => {
      // Assign value to map prop
      props.xoneDataObject.changeModelValue(
        props.attributes.linkedTo,
        mapValue
      );
      // Assing value to prop
      propValue.value = value;
    };

    /**
     * prop model value
     * @type {Ref<any>}
     */
    const propValue = ref();

    onMounted(() =>
      onChangeModelValue(props.xoneDataObject.model[props.attributes.name])
    );

    /**
     * textAreaElement
     * @type {Ref<HTMLElement|null>}
     */
    const textAreaElement = ref();

    nextTick(() => textAreaAdjust());

    //
    //Adjust textarea element height auto
    const textAreaAdjust = () => {
      if (!props.attributes.multiLine) return;
      if (!textAreaElement.value) return;
      if (props.attributes.height !== "auto") return;

      Promise.resolve().then(async () => {
        await nextTick();
        textAreaElement.value.style.height = "1px";
        textAreaElement.value.style.height =
          25 + textAreaElement.value.scrollHeight + "px";
      });
    };

    // Get appData model value
    watch(
      () => props.xoneDataObject.model[props.attributes.name],
      (newValue) => onChangeModelValue(newValue)
    );

    // On Model Value Changed
    const onChangeModelValue = (newValue) => {
      try {
        textAreaAdjust();

        if (props.attributes.type.startsWith("D")) {
          /**
           * value
           * @type {Date}
           */
          const value = newValue;
          if (!value) return;
          propValue.value = value.toXoneDate(props.attributes.type);
        } else if (propValue.value !== newValue) propValue.value = newValue;
      } catch (ex) {
        console.error(ex);
      }
    };

    // Last key pressed
    let keyPressed = "";
    let isExecutingAction = false;

    const onKeyPress = ({ key }) => {
      if (key !== "Enter") return;
      // set Last key
      keyPressed = key;
      // Execute oneditaction
      if (props.attributes.onEditorAction && !isExecutingAction) {
        isExecutingAction = true;
        xoneAttributesHandler
          .executeMethod(props.attributes.onEditorAction, props.xoneDataObject)
          .finally(() => (isExecutingAction = false));
      }
      // Execute binded event in script
      xoneAttributesHandler.executeBindedEvent(
        props.xoneDataObject,
        props.attributes,
        "oneditoraction",
        {}
      );
    };

    // Update appData model value
    const onInput = async ({ target }) => {
      const oldValue = propValue.value;
      const newValue = target.value;
      // In mobile keypress does not work
      if (!keyPressed || keyPressed === "Unidentified") {
        if (newValue.includes(oldValue))
          keyPressed = newValue.replace(oldValue, "");
      }

      // propValue.value = target.value;
      if (props.xoneDataObject[props.attributes.name] === newValue) return;

      try {
        // Update data model
        if (props.attributes.type.startsWith("D")) {
          props.xoneDataObject.changeModelValue(
            props.attributes.name,
            new Date(newValue)
          );
        } else
          props.xoneDataObject.changeModelValue(
            props.attributes.name,
            newValue
          );

        try {
          if (props.attributes.onTextChanged)
            await props.xoneDataObject.DoRunScriptAsync(
              `let e = { target: '${props.attributes.name}',
                    objItem: '${props.attributes.name}',
                    newText: '${newValue}', 
                    oldText: '${oldValue}', 
                    keyPressed: '${keyPressed}' };
                  ${props.attributes.onTextChanged}`
            );
        } catch (ex) {
          xoneUI.showSnackbar({
            text: ex,
            color: "red",
          });
        }
        // Execute binded event in script
        await xoneAttributesHandler.executeBindedEvent(
          props.xoneDataObject,
          props.attributes,
          "ontextchanged",
          { newText: newValue, oldText: oldValue, keyPressed }
        );
      } catch (ex) {
        console.error(ex);
      }
    };

    /**
     * on value change
     */
    const onChange = async () => {
      try {
        if (editInRow && objectInfo.autosave) {
          await props.xoneDataObject.save();
        }
      } catch (ex) {
        xoneUI.showSnackbar({
          text: ex,
          color: "red",
        });
      }
    };

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");
    onMounted(() =>
      // Add control to view
      xoneView.addControl(new XoneControl(props.attributes.name))
    );

    // Check if an input is focused in order to handle keyboard in mobile devices
    const isInputFocused = inject("isInputFocused");

    onUnmounted(() => (isInputFocused.value = false));

    /**
     * on focus changed
     */
    const onFocusChanged = async (isFocused) => {
      try {
        isInputFocused.value = isFocused;
        if (props.attributes.onFocusChanged)
          await props.xoneDataObject.DoRunScriptAsync(
            `let e = { target: '${props.attributes.name}',objItem: '${props.attributes.name}',isFocused: ${isFocused} };
            ${props.attributes.onFocusChanged}`
          );
      } catch (ex) {
        xoneUI.showSnackbar({
          text: ex,
          color: "red",
        });
      }
      // Execute binded event in script
      await xoneAttributesHandler.executeBindedEvent(
        props.xoneDataObject,
        props.attributes,
        "onfocuschanged",
        { isFocused }
      );
    };

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>|ComputedRef<number>, containerWidth: Ref<number>|ComputedRef<number>}}
     */
    const { containerHeight, containerWidth } = inject("containerSize");
    watch(
      () => containerHeight.value,
      () => textAreaAdjust()
    );

    /**
     * floating tooltip on focus transform
     * @type {ComputedRef<string|null>}
     */
    const floatingTooltipTransform = computed(() => {
      if (!props.attributes.floatingTooltip) return null; // Not floating prop
      if (props.controlHeight && props.controlHeight !== 0)
        // Is floating and returns control height
        return `translate3d(-.5rem, calc(${
          // Is floating and returns default value
          (-1 * props.controlHeight) / 2
        }px + .4rem), 0)`;
      return "translate3d(-.5rem, -2rem, 0)";
    });

    /**
     * linkedSearchElement
     * @type {Ref<HTMLElement>}
     */
    const linkedSearchElement = ref();

    /**
     * inputElement
     * @type {Ref<HTMLElement>}
     */
    const inputElement = ref();

    const linkedSearchPosition = ref();

    const onLinkedSearchButtonClick = () => {
      calculateLinkedSearchButtonPosition();
      isLinkedSearchVisible.value = true;
    };

    const calculateLinkedSearchButtonPosition = () => {
      if (!linkedSearchElement.value) return;

      const element = inputElement.value || textAreaElement.value;

      linkedSearchPosition.value = {
        top: linkedSearchElement.value.getBoundingClientRect().top,
        left:
          linkedSearchElement.value.getBoundingClientRect().left -
          (window.innerWidth - containerWidth.value) / 2,
        width: element.offsetWidth - linkedSearchElement.value.offsetWidth,
        height: element.offsetHeight,
      };
    };

    return {
      numberStep,
      isLinked,
      isLinkedSearchVisible,
      onSelectedItem,
      propValue,
      objectInfo,
      textAreaElement,
      onInput,
      onChange,
      onFocusChanged,
      onKeyPress,
      floatingTooltipTransform,
      onLinkedSearchButtonClick,
      linkedSearchElement,
      inputElement,
      linkedSearchPosition,
    };
  },
};
</script>

<style scoped>
* {
  font-weight: var(--text-weight);
}

/* label container */
.xone-input-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* border-radius: 3px; */
}

/* prepend label */
.xone-title {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  vertical-align: middle;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin: 5px;
}

/* input */
.xone-input-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 2;
}

input,
textarea {
  flex-grow: 1;
  flex-shrink: 2;
  border: 1px solid gray;
  width: 12rem;
  resize: none;
  background-color: var(--input-bgcolor);
  color: var(--input-forecolor);
  padding-top: var(--padding-top);
  padding-right: var(--padding-right);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
}

input:focus,
textarea:focus {
  background-color: rgba(0, 0, 0, 0.05) !important;
  outline: none;
}

.xone-input-linked {
  position: absolute;
  right: 0;
  background-color: transparent;
  border: none;
  background-image: url(/assets/showinline.png); /**url(/assets/search.png);*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  outline: none;
  padding: 10px 20px;
  width: 30px;
  height: 30px;
}
.xone-input-linked:hover {
  cursor: pointer;
  border-radius: 3px;
}

.noEditInRow {
  cursor: pointer;
  pointer-events: none;
}

.nobordertop {
  border-top: 0;
}

.noborderright {
  border-right: 0;
}

.noborderbottom {
  border-bottom: 0;
}

.noborderleft {
  border-left: 0;
}

/*
.disabled {
   color: lightgray; 
}*/

/* tooltip */
.xone-tooltip {
  position: absolute;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  flex-grow: 0;
  font-size: 0.8rem;
  height: auto;
  pointer-events: none;
}

.float {
  top: auto;
}

.nofloat {
  top: auto;
  width: 100%;
}

input:focus + .float,
input:valid + .float,
.linked-input-fill {
  font-size: 0.8rem;
  transform: var(--floating-tooltip-transform);
  opacity: 1;
}

input:focus + .nofloat,
input:valid + .nofloat {
  opacity: 0;
}
</style>