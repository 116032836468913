<template>
  <div
    v-show="!isDisableVisible"
    class="xone-prop"
    ref="propElement"
    :xone-name="attributes.name"
    :id="attributes.name"
    :style="{
      // set variables
      '--label-height':
        (controlHeight && `${controlHeight}px`) ||
        `calc(${attributes.lines} + 11px)` ||
        '100%',
      '--text-weight': attributes.fontBold ? 'bold' : 'normal',
      '--input-bgcolor':
        ((attributes.locked || isDisableEdit) &&
          attributes.textBgColorDisabled &&
          attributes.textBgColorDisabled) ||
        attributes.textBgColor ||
        attributes.bgColor ||
        'transparent',
      '--input-forecolor':
        ((attributes.locked || isDisableEdit) &&
          attributes.textForeColorDisabled &&
          attributes.textForeColorDisabled) ||
        (attributes.textForeColor && attributes.textForeColor) ||
        attributes.foreColor,
      '--padding-top': `${paddings.top}px`,
      '--padding-right': `${paddings.right}px`,
      '--padding-bottom': `${paddings.bottom}px`,
      '--padding-left': `${paddings.left}px`,
      '--margin-top': `${margins.top}px`,
      '--margin-right': `${margins.right}px`,
      '--margin-bottom': `${margins.bottom}px`,
      '--margin-left': `${margins.left}px`,
      '--border-color': attributes.borderColor,
      '--border-width': attributes.borderWidth,
      '--elevation': `${attributes.elevation}px`,
      // Size
      width:
        (controlWidth && `${controlWidth}px`) ||
        (imgSize && `${imgSize.width}px`),
      height:
        (controlHeight && `${controlHeight}px`) ||
        (imgSize && `${imgSize.height}px`) ||
        (attributes.height ?? 'auto'),
      // Background
      backgroundColor: displayScreenThresholds && '#ff000025',
      position: attributes.floating.floating && 'absolute',
      top: attributes.floating.floating && floatingTop && floatingTop,
      left: attributes.floating.floating && floatingLeft && floatingLeft,
      // Border
      border: displayScreenThresholds && '1px solid #ff0000',
      // Designer mode
      cursor: isDesigner && isDragging && 'move',
    }"
    @mousedown="isDesigner && onMouseDown($event)"
    @mouseenter="isDesigner && onMouseEnter($event)"
    @mouseleave="isDesigner && onMouseLeave($event)"
    @mousemove="isDesigner && onDragOver($event)"
    @touchmove="isDesigner && onDragOver($event)"
  >
    <!-- Input Prop -->
    <InputProp
      v-if="isInput"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
      :inputType="inputType"
      :isDisableEdit="isDisableEdit"
    ></InputProp>
    <!-- Button -->
    <ButtonProp
      v-if="isButton"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :paddings="paddings"
      :isDisableEdit="isDisableEdit"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></ButtonProp>
    <!-- Img / Photo / Draw -->
    <ImageProp
      v-if="isImage"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></ImageProp>
    <!-- Contents -->
    <Contents
      v-if="isContents"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth || containerWidth"
      :controlHeight="controlHeight || containerHeight"
      :isDisableEdit="isDisableEdit"
    ></Contents>
    <!-- Web -->
    <WebProp
      v-if="isWeb"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
    ></WebProp>
    <!-- Video -->
    <VideoProp
      v-if="isVideo"
      :xoneDataObject="xoneDataObject"
      :attributes="attributes"
      :controlWidth="controlWidth"
      :controlHeight="controlHeight"
    ></VideoProp>
    <Breadcumb v-if="isBreadcumb" :attributes="attributes"></Breadcumb>
  </div>
</template>

<script>
// vue
import {
  computed,
  inject,
  ref,
  Ref,
  ComputedRef,
  onMounted,
  watch,
  nextTick,
} from "vue";
// components
import ButtonProp from "@/components/propComponents/Button";
import InputProp from "@/components/propComponents/Input";
import ImageProp from "@/components/propComponents/Image";
import Contents from "@/components/propComponents/Contents";
import WebProp from "@/components/propComponents/Web";
import VideoProp from "@/components/propComponents/Video";
import Breadcumb from "@/components/propComponents/Breadcumb";
// composables
import {
  xoneAttributesHandler,
  PropAttributes,
  Margins,
  Paddings,
} from "../composables/XoneAttributesHandler";
import dragAndDrop from "../composables/DragAndDrop";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";

export default {
  name: "Prop",
  props: {
    /** xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    control: { type: Object, default: undefined, required: true },
    containerWidth: { type: Number, default: 0 },
    containerHeight: { type: Number, default: 0 },
  },
  components: {
    ButtonProp,
    InputProp,
    ImageProp,
    Contents,
    WebProp,
    VideoProp,
    Breadcumb,
  },
  setup(props) {
    /**
     * @type {Ref<HTMLDivElement>}
     */
    const propElement = ref();

    const displayScreenThresholds = inject("displayScreenThresholds");

    /**
     * Attributes model
     * @type {ComputedRef<PropAttributes>}
     */
    const attributesModel = computed(() =>
      xoneAttributesHandler.getPropAttributes(props.control.attributes)
    );

    /**
     * Reactive attributes
     * @type {Ref<PropAttributes>}
     */
    const attributes = ref({});

    // Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
    xoneAttributesHandler.watchAttributes(
      attributes,
      attributesModel,
      props.xoneDataObject?.model
    );

    /**
     * Check if disablevisible
     * @type {ComputedRef<boolean>}
     */
    const isDisableVisible = computed(() => {
      if ((attributes.value.disableVisible ?? "") === "") return false;
      return xoneAttributesHandler.evalFormula(
        attributes.value.disableVisible,
        props.xoneDataObject?.model
      );
    });

    /**
     * Image size
     * @type {Ref<number|null>}
     */
    let imgSize = ref(null);

    const calculateImgSize = () => {
      if (!attributes.value.image) return;
      const img = new Image();
      img.src = `/icons/${attributes.value.image}`;
      img.onload = function () {
        imgSize.value = {
          width: xoneAttributesHandler.getScaledSize(
            this.width + "px",
            props.containerWidth,
            widthFactor.value
          ),
          height: xoneAttributesHandler.getScaledSize(
            this.height + "px", // offset height
            props.containerHeight,
            heightFactor.value
          ),
        };
      };
    };

    // Recalculate image size if attributes.image change
    onMounted(() => calculateImgSize());

    watch(
      () => props.containerHeight,
      () => calculateImgSize()
    );

    /**
     * Input type depending on prop type
     * @type {ComputedRef<string>}
     */
    const inputType = computed(() => {
      if (
        attributes.value.viewMode === "breadcumbview" ||
        attributes.value.viewMode === "routerview"
      )
        return null;
      switch (attributes.value.type) {
        case "T":
          return "text";
        case "X":
          return "password";
        case "N":
          if (attributes.value.viewMode === "slider") return "range";
          return "number";
        case "NC":
          return "checkbox";
        case "D":
          return "date";
        case "TT":
          return "time";
        case "DT":
          return "datetime-local";
        case "AT":
          return "file";
        case "TL":
          return "text";
        default:
          if (
            attributes.value.type.length != 0 &&
            attributes.value.type[0] === "N"
          )
            return "number";
          return null;
      }
    });

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    /**
     * Calculate width of the current prop
     * @type {ComputedRef<number>}
     */
    const controlWidth = computed(() =>
      xoneAttributesHandler.getScaledSize(
        attributes.value.width,
        props.containerWidth,
        widthFactor.value
      )
    );

    /**
     * Calculate height of the current prop
     * @type {ComputedRef<number>}
     */
    const controlHeight = computed(() =>
      xoneAttributesHandler.getScaledSize(
        attributes.value.height,
        props.containerHeight,
        heightFactor.value
      )
    );

    /**
     * Margins of the current prop
     * @type {ComputedRef<Margins>}
     */
    const margins = computed(() => ({
      top:
        xoneAttributesHandler.getScaledSize(
          attributes.value.margins.top,
          props.containerHeight,
          heightFactor.value
        ) ?? 0,
      left:
        xoneAttributesHandler.getScaledSize(
          attributes.value.margins.left,
          props.containerWidth,
          widthFactor.value
        ) ?? 0,
      bottom:
        xoneAttributesHandler.getScaledSize(
          attributes.value.margins.bottom,
          props.containerHeight,
          heightFactor.value
        ) ?? 0,
      right:
        xoneAttributesHandler.getScaledSize(
          attributes.value.margins.right,
          props.containerWidth,
          widthFactor.value
        ) ?? 0,
    }));

    /**
     * calculate floating top
     * @type {ComputedRef<string>}
     */
    const floatingTop = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.top,
        heightFactor.value
      )
    );

    /**
     * calculate floating leeft
     * @type {ComputedRef<string>}
     */
    const floatingLeft = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.left,
        widthFactor.value
      )
    );

    /**
     * Check if disableedit
     * @type {ComputedRef<boolean>}
     */
    const isDisableEdit = computed(() => {
      if ((attributes.value.disableEdit ?? "") === "") return false;
      return xoneAttributesHandler.evalFormula(
        attributes.value.disableEdit,
        props.xoneDataObject.model
      );
    });

    /**
     * Padding
     * @type {ComputedRef<Paddings>}
     */
    const paddings = computed(() => {
      return {
        top:
          xoneAttributesHandler.getScaledSize(
            attributes.value.paddings.top,
            props.containerHeight,
            heightFactor.value
          ) || 5,
        right:
          xoneAttributesHandler.getScaledSize(
            attributes.value.paddings.right,
            props.containerWidth,
            widthFactor.value
          ) || 5,
        bottom:
          xoneAttributesHandler.getScaledSize(
            attributes.value.paddings.bottom,
            props.containerHeight,
            heightFactor.value
          ) || 5,
        left:
          xoneAttributesHandler.getScaledSize(
            attributes.value.paddings.left,
            props.containerWidth,
            widthFactor.value
          ) || 5,
      };
    });

    // Execute postonchange method

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<import('../composables/AppDataHandler').Breadcumb>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Inject breadcumbId info to child components
    const breadcumbId = inject("breadcumbId");

    nextTick(() => {
      if (!attributes.value.postOnchange) return;
      watch(
        () => lastBreadcumb.value,
        async (newValue) => {
          if (newValue.id !== breadcumbId) return;
          xoneAttributesHandler.executeMethod(
            attributes.value.postOnchange,
            props.xoneDataObject,
            attributes.value.name
          );
        }
      );
    });

    //
    // Designer mode Drag & Drop
    const onMouseEnter = () => dragAndDrop.mouseEnter(propElement.value);
    const onMouseLeave = () => dragAndDrop.mouseLeave(propElement.value);
    const onMouseDown = (e) => dragAndDrop.mouseDown(e);
    const onDragOver = (e) => dragAndDrop.mouseMove(e);

    return {
      propElement,
      displayScreenThresholds,
      attributes,
      inputType,
      controlWidth,
      controlHeight,
      margins,
      isDisableVisible,
      imgSize,
      floatingTop,
      floatingLeft,
      isDisableEdit,
      paddings,
      isInput: computed(() => inputType.value),
      isButton: computed(() => attributes.value.type === "B"),
      isImage: computed(
        () =>
          attributes.value.type === "IMG" ||
          attributes.value.type === "PH" ||
          attributes.value.type === "DR"
      ),
      isContents: computed(
        () =>
          attributes.value.type === "Z" ||
          attributes.value.viewMode === "routerview"
      ),
      isWeb: computed(() => attributes.value.type === "WEB"),
      isVideo: computed(() => attributes.value.type === "VD"),
      isBreadcumb: computed(
        () => attributes.value.viewMode === "breadcumbview"
      ),
      isDesigner: dragAndDrop.getIsDesigner(),
      isDragging: dragAndDrop.getIsDragging(),
      isDesignerPropSelected: computed(
        () => dragAndDrop.getSelectedControl()?.value === propElement.value
      ),
      borderDesignSelected: dragAndDrop.getBorderSelected(),
      borderDesignOver: dragAndDrop.getBorderPropOver(),
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      onDragOver,
    };
  },
};
</script>

<style scoped >
.xone-prop {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin-top: var(--margin-top);
  margin-right: var(--margin-right);
  margin-bottom: var(--margin-bottom);
  margin-left: var(--margin-left);
}
</style>
