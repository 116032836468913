<template>
  <iframe
    v-if="isUrl"
    :src="propValue"
    :width="controlWidth"
    :height="controlHeight"
  ></iframe>
  <div v-if="!isUrl" :id="`web-${attributes.name}-${breadcumbId}`"></div>
</template>

<script>
import { inject, nextTick, PropType, ref, Ref, watchEffect } from "vue";
import { PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";

export default {
  props: {
    /** xoneDataObject
     @type {PropType<XoneDataObject>} 
     */
    xoneDataObject: { type: Object, required: true },
    /** attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    const isUrl = ref(false);

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * prop model value
     * @type {Ref<any>}
     */
    let propValue = ref();

    // Get appData model value
    watchEffect(async () => {
      if (props.xoneDataObject?.model)
        propValue.value = props.xoneDataObject?.model[props.attributes.name];

      if (
        !propValue.value?.toString().contains("<") &&
        !propValue.value?.toString().contains(">")
      )
        // URL
        return (isUrl.value = true);

      isUrl.value = false;
      await nextTick();
      // No URL
      const divElement = document.getElementById(
        `web-${props.attributes.name}-${breadcumbId}`
      );
      const shadow = divElement.attachShadow({ mode: "closed" });

      shadow.innerHTML = propValue.value;

      shadow.querySelectorAll("script").forEach((e) => {
        Function("document", "getSize", e.innerText)(shadow, () => {
          return { width: props.controlWidth, height: props.controlHeight };
        });
      });
    });

    return { propValue, isUrl, breadcumbId };
  },
};
</script>

<style scoped>
iframe {
  border: none;
}
</style>