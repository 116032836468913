import { watchEffect, Ref, nextTick } from "vue";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { getView } from "./XoneViewsHandler";

/**
 * Types Definitions
 */

/**
 * @typedef {Object} Margins
 * @property {string} top
 * @property {string} right
 * @property {string} bottom
 * @property {string} left
 */

/**
 * @typedef {Object} Paddings
 * @property {string} top
 * @property {string} right
 * @property {string} bottom
 * @property {string} left
 */

/**
 * @typedef {Object} Borders
 * @property {boolean} top
 * @property {boolean} right
 * @property {boolean} bottom
 * @property {boolean} left
 */

/**
 * @typedef {Object} ContainerAlign
 * @property {string} row
 * @property {string} column
 */

/**
 * @typedef {Object} ContainerAttributes
 * @property {string} node
 * @property {string} name
 * @property {string} id
 * @property {Margins} margins
 * @property {string} tMargin
 * @property {string} rMargin
 * @property {string} bMargin
 * @property {string} lMargin
 * @property {Borders} borders
 * @property {string} bgColor
 * @property {string} foreColor
 * @property {string} width
 * @property {string} height
 * @property {string} fixed
 * @property {string} orientation
 * @property {boolean} newLine
 * @property {Object} align
 * @property {boolean} noTab
 * @property {string} tabFontSize
 * @property {string} tabForecolor
 * @property {string} tabSelectedForecolor
 * @property {string} tabBgColor
 * @property {Paddings} tabPaddings
 * @property {Paddings} paddings
 * @property {string} image
 * @property {boolean} keepAspectRatio
 * @property {string} borderCornerRadius
 * @property {number} borderWidth
 * @property {string} disableVisible
 * @property {string} drawerOrientation
 * @property {boolean} scroll
 * @property {string} scrollOrientation
 * @property {Object} floating
 * @property {string} cellOddColor
 * @property {string} cellEvenColor
 * @property {boolean} loadAll
 * @property {boolean} framebox
 * @property {string} onFocus
 * @property {string} viewMode
 * @property {string} href
 * @property {string} cellSelectedBgColor
 * @property {string} animationIn
 * @property {string} animationOut
 * @property {string} borderColor
 * @property {number} elevation
 */

/**
 * @typedef {Object} PropAttributes
 * @property {string} node
 * @property {string} name
 * @property {string} type
 * @property {string} title
 * @property {boolean|Number} isVisible
 * @property {Object} margins
 * @property {string} tMargin
 * @property {string} rMargin
 * @property {string} bMargin
 * @property {string} lMargin
 * @property {Borders} borders
 * @property {number} borderWidth
 * @property {Object} textBorder
 * @property {boolean} textBorderTop
 * @property {boolean} textBorderRight
 * @property {boolean} textBorderBottom
 * @property {boolean} textBorderLeft
 * @property {boolean} floatingTooltip
 * @property {string} tooltip
 * @property {ContainerAlign|string} align
 * @property {string} labelAlign
 * @property {string} textAlign
 * @property {string} textBgColor
 * @property {string} textForeColor
 * @property {string} textForeColorDisabled
 * @property {string} textBgColorDisabled
 * @property {string} bgColor
 * @property {string} foreColor
 * @property {string} fontSize
 * @property {string} textFontSize
 * @property {string} tooltipForeColor
 * @property {string} width
 * @property {string} height
 * @property {number} size
 * @property {boolean} fixedText
 * @property {string} labelWidth
 * @property {string} fieldSize
 * @property {string} lines
 * @property {boolean} multiLine
 * @property {string} image
 * @property {boolean} keepAspectRatio
 * @property {string} borderCornerRadius
 * @property {string} disableEdit
 * @property {string} disableVisible
 * @property {boolean} locked
 * @property {Object} method
 * @property {Object} onClick
 * @property {Object} onTextChanged
 * @property {Object} onFocusChanged
 * @property {Object} contents
 * @property {boolean} editInRow
 * @property {boolean} readOnly
 * @property {Object} floating
 * @property {string} viewMode
 * @property {number} galleryColumns
 * @property {string} cellOddColor
 * @property {string} cellEvenColor
 * @property {Number} autoslideDelay
 * @property {string} tooltipColor
 * @property {string} linkedTo
 * @property {string} linkedField
 * @property {string} calendarViewMode
 * @property {string} cellBgColor
 * @property {string} cellForeColor
 * @property {string} cellBorderColor
 * @property {string} cellSelectedBgColor
 * @property {string} cellSelectedForeColor
 * @property {string} cellSelectedBorderColor
 * @property {string} cellOtherMonthBgColor
 * @property {boolean} weekdaysLongname
 * @property {boolean} fontBold
 * @property {string} imgChecked
 * @property {string} imgUnchecked
 * @property {string} imgCheckedDisabled
 * @property {string} imgUncheckedDisabled
 * @property {string} imgWidth
 * @property {string} imgHeight
 * @property {boolean} autosave
 * @property {string} href
 * @property {string} onEditorAction
 * @property {string} buttonOption
 * @property {Paddings} paddings
 * @property {boolean} showInline
 * @property {boolean} showInlineKeyboard
 * @property {string} postOnchange
 * @property {boolean} labelBox
 * @property {string} borderColor
 * @property {number} elevation
 * @property {number} breadcumbForeColor
 * @property {number} breadcumbFontSize
 * @property {number} breadcumbSelectedForeColor
 * @property {number} breadcumbSelectedFontSize
 * @property {bool} isRadio
 * @property {string} radioGroup
 */

/**
 * Class to handle mappings attributes
 */
class XoneAttributesHandler {
  /**
   * _instance
   * @type {XoneAttributesHandler}
   */
  static _instance;

  /**
   * mapFormulaFunctions
   * @type {Map<string,Function>}
   */
  _mapFormulaFunctions = new Map();

  constructor() {
    if (XoneAttributesHandler._instance) return XoneAttributesHandler._instance;

    XoneAttributesHandler._instance = this;
  }

  /**
   * Convert xone units to html units
   * @param {any} value
   * @param {boolean} [isLine]
   * @returns {string}
   */
  convertUnits(value, isLine = false) {
    if (!value) return;

    if (value.toString().includes("px") || value.toString().includes("%"))
      return value;

    if (value.toString().includes("p"))
      return value.toString().replace("p", "px");

    if (isNaN(Number(value))) return value;

    const num = parseInt(value);
    if (num < 0) return "auto";

    if (isLine) return num + 0.5 + "em";

    return num === 0 ? "0" : `${num}em`;
  }

  /**
   * Get Paddings
   * @param {Object} attributes
   * @returns {Paddings}
   */
  getPaddings(attributes) {
    return {
      top: this.convertUnits(attributes.tPadding ?? 0),
      right: this.convertUnits(attributes.rPadding ?? 0),
      bottom: this.convertUnits(attributes.bPadding ?? 0),
      left: this.convertUnits(attributes.lPadding ?? 0),
    };
  }
  /**
   * Get Paddings
   * @param {Object} attributes
   * @returns {Paddings}
   */
  getTabPaddings(attributes) {
    return {
      top: this.convertUnits(attributes.tabTPadding ?? 0),
      right: this.convertUnits(attributes.tabRPadding ?? 0),
      bottom: this.convertUnits(attributes.tabBPadding ?? 0),
      left: this.convertUnits(attributes.tabLPadding ?? 0),
    };
  }

  /**
   * Resolve margins
   * @param {Object} attributes
   * @returns {Margins}
   */
  getMargins(attributes) {
    /**
     * Margins
     * @type {Array<string>}
     */
    let [top, right, bottom, left] = [
      attributes.tMargin ?? "0",
      attributes.rMargin ?? "0",
      attributes.bMargin ?? "0",
      attributes.lMargin ?? "0",
    ].map((e) => this.convertUnits(e));
    if (attributes.node === "prop" && attributes.floatingTooltip && top === "0")
      top = "1rem";
    return { top, right, bottom, left };
  }

  /**
   * Resolve borders
   * @param {Object} attributes
   * @returns {Borders}
   */
  getBorders(attributes) {
    const [top, right, bottom, left] = [
      (attributes.textBorder ?? "true") === "true" &&
        (attributes.textBorderTop ?? "true") === "true",
      (attributes.textBorder ?? "true") === "true" &&
        (attributes.textBorderRight ?? "true") === "true",
      (attributes.textBorder ?? "true") === "true" &&
        (attributes.textBorderBottom ?? "true") === "true",
      (attributes.textBorder ?? "true") === "true" &&
        (attributes.textBorderLeft ?? "true") === "true",
    ];

    return { top, right, bottom, left };
  }

  /**
   * Get label align
   * @param {*} align
   */
  getLabelAlign(align) {
    if (!align) return;
    if (align === "left") return "flex-start";
    if (align === "right") return "flex-end";
    if (align === "center") return "center";
  }

  /**
   * Get prop align
   * @param {Object} attributes
   */
  getPropAlign(attributes) {
    let align = attributes.align;
    if (!align) {
      const type = this.getPropType(attributes);
      if (type.startsWith("N")) return "end";
    }
    if (align?.toString().includes("left")) return "start";
    if (align?.toString().includes("right")) return "end";
    if (align?.toString().includes("center")) return "center";
  }

  /**
   * Resolve container align
   * @param {Object} attributes
   * @returns {ContainerAlign}
   */
  getContainerAlign(attributes) {
    let align = (attributes.align ?? "").toString(),
      row = "",
      column = "";
    if (align.includes("left")) row = "flex-start";
    if (align.includes("right")) row = "flex-end";
    if (align.includes("top")) column = "flex-start";
    if (align.includes("bottom")) column = "flex-end";
    if (align.includes("center")) {
      if (align === "center") {
        row = "center";
        column = "center";
      } else if (row === "") row = "center";
      else column = "center";
      if (column === "" && align.replace("center", "").includes("center"))
        column = "center";
    }

    return { row, column };
  }

  /**
   * Node visibility depending on the visibility bit
   * @param {Object} attributes
   * @param {Number} [visibilityBit]
   */
  isNodeVisible(attributes, visibilityBit = 1) {
    return true;
    // Comentado porque ya viene en el getLayout de appData
    // return (attributes.visible ?? 1) & Number(visibilityBit << 0 !== 0);
  }

  /**
   * Calculate control size depending on container size and attribute width/height
   * @param {string|Number|undefined} attributeSize
   * @param {Number} containerSize
   * @param {Number} scaleFactor
   */
  getScaledSize(attributeSize, containerSize, scaleFactor) {
    let size;

    if (!attributeSize) return;

    if (attributeSize.toString().includes("px"))
      size = scaleFactor * parseInt(attributeSize.toString().replace("px", ""));
    else if (attributeSize.toString().includes("%"))
      size =
        (containerSize * parseInt(attributeSize.toString().replace("%", ""))) /
        100;

    return size;
  }

  /**
   * Calculate control position on container depending on size and scale
   * @param {string} position
   * @param {number} scaleFactor
   * @returns {string}
   */
  getScaledPosition(position, scaleFactor) {
    /** value
     * @type {string|number}
     */
    let value = position;

    if (!value) return;

    if (value.toString().includes("px")) {
      value = value.replace("px", "");
      if (isNaN(Number(value))) return;
      return `${Number(value) * scaleFactor}px`;
    }
    return value;
  }

  /**
   * Calculate and adjust height to parent container when height attribute is null or auto and height > containerHeight
   * @param {PropAttributes} attributes
   * @param {Ref<HTMLElement>} htmlElement
   */
  fitHeightToContainer = async (attributes, htmlElement) => {
    try {
      if (attributes.height !== "auto" && attributes.width == "auto") return;
      await nextTick();

      /**
       * frame / group parent element
       * @type {HTMLElement}
       */
      const containerElement =
        htmlElement.value?.parentElement?.parentElement?.parentElement;
      if (!containerElement) return;

      if (
        containerElement.parentElement &&
        containerElement.parentElement.classList.contains("xone-contents-row")
      ) {
        // If parent is contents row and  maxHeight is setted, reset and fit again
        if (htmlElement.value.style.maxHeight) {
          htmlElement.value.style.maxHeight = null; // button element
          htmlElement.value.parentElement.style.maxHeight = null; // prop element
          htmlElement.value.parentElement.parentElement.style.maxHeight = null; // row element
          return setTimeout(
            () => this.fitHeightToContainer(attributes, htmlElement),
            100
          );
        }
      }

      if (!containerElement || containerElement.offsetHeight === 0) return;

      let maxHeight = `calc(${containerElement.offsetHeight}px + var(--margin-top) + var(--margin-bottom))`; // container width

      htmlElement.value.style.maxHeight = maxHeight; // button element
      // htmlElement.value.style.objectFit = "contain";
      htmlElement.value.parentElement.style.maxHeight = maxHeight; // prop element
      htmlElement.value.parentElement.parentElement.style.maxHeight = maxHeight; // row element
    } catch {}
  };

  /**
   * Calculate and adjust width to parent container when width attribute is null or auto and width > containerWidth
   * @param {PropAttributes} attributes
   * @param {Ref<HTMLElement>} htmlElement
   */
  fitWidthToContainer = async (attributes, htmlElement) => {
    try {
      if (attributes.height === "auto" && attributes.width !== "auto") return;

      await nextTick();

      /**
       * frame / group parent element
       * @type {HTMLElement}
       */
      const containerElement =
        htmlElement.value?.parentElement?.parentElement?.parentElement;

      if (!containerElement) return;

      // If parent is contents row and  maxWidth is setted, reset and fit again
      if (
        containerElement.parentElement &&
        containerElement.parentElement.classList.contains("xone-contents-row")
      ) {
        if (htmlElement.value.style.maxWidth) {
          htmlElement.value.style.maxWidth = null; // button element
          htmlElement.value.parentElement.style.maxWidth = null; // prop element
          htmlElement.value.parentElement.parentElement.style.maxWidth = null; // row element
          return setTimeout(
            () => this.fitWidthToContainer(attributes, htmlElement),
            100
          );
        }
      }

      if (!containerElement || containerElement.offsetWidth === 0) return;

      let maxWidth = `calc(${containerElement.offsetWidth}px + var(--margin-right) + var(--margin-left))`; // container width

      htmlElement.value.style.maxWidth = maxWidth; // button element
      // htmlElement.value.style.objectFit = "contain";
      htmlElement.value.parentElement.style.maxWidth = maxWidth; // prop element
      htmlElement.value.parentElement.parentElement.style.maxWidth = maxWidth; // row element
    } catch {}
  };

  /**
   * Calculate font size
   * @param {string} fontSize
   */
  getFontSize(fontSize) {
    const u = window.innerWidth > window.innerHeight ? "vh" : "vw";
    if (!fontSize) fontSize = "8";
    if (isNaN(Number(fontSize))) return fontSize;
    return `calc(${Number(fontSize) + 3}px + .5${u})`;
  }

  /**
   * Get prop type
   * @param {Object} attributes
   */
  getPropType(attributes) {
    let type = attributes.type ?? "T";
    if (type === "T") {
      if (attributes.mask ?? "" !== "") type = "TT";
    }
    return type;
  }

  /**
   * Resolve image route
   * @param {string} img
   */
  getImage(img) {
    if (!img) return null;
    if (!img?.includes("./icons/"))
      img = img
        .replace("./icons/", "")
        .replace("##APP##", "")
        .replace("##APPPATH##", "")
        .replace("\\icons\\", "");

    return img;
  }

  /**
   * Resolve floating / top / left attributes
   * @param {*} attributes
   * @returns {Object}
   */
  getFloating(attributes) {
    if (
      !attributes.floating ||
      !attributes.top ||
      !attributes.left ||
      attributes.floating !== "true"
    )
      return { floating: false };
    let top = attributes.top;
    if (top.includes("p") && !top.includes("px")) top += "x";
    let left = attributes.left;
    if (left.includes("p") && !left.includes("px")) left += "x";
    return { floating: true, top, left };
  }

  /**
   * getAnimation
   * @param {string} animation
   */
  getAnimation(animation) {
    return animation
      ?.toString()
      .toLowerCase()
      .replace(/#/g, "");
  }

  /**
   * Attributes for prop
   * @param {Object} attributes
   * @param {Number} visibilityBit
   * @returns {PropAttributes}
   */
  getPropAttributes(attributes, visibilityBit = 1) {
    return {
      node: attributes.node,
      name: attributes.name,
      type: this.getPropType(attributes),
      title: attributes.title,
      isVisible: this.isNodeVisible(attributes, visibilityBit),
      margins: this.getMargins(attributes),
      tMargin: attributes.tMargin,
      rMargin: attributes.rMargin,
      bMargin: attributes.bMargin,
      lMargin: attributes.lMargin,
      borders: this.getBorders(attributes),
      textBorder: attributes.textBorder,
      textBorderTop: attributes.textBorderTop,
      textBorderRight: attributes.textBorderRight,
      textBorderBottom: attributes.textBorderBottom,
      textBorderLeft: attributes.textBorderLeft,
      floatingTooltip: attributes.floatingTooltip ?? false,
      tooltip: attributes.tooltip,
      align:
        attributes.type === "B"
          ? this.getContainerAlign(attributes)
          : this.getPropAlign(attributes),
      labelAlign: this.getLabelAlign(attributes.align),
      textAlign: attributes.textAlign ?? attributes.align,
      textBgColor: attributes.textBgColor,
      textForeColor: attributes.textForeColor,
      textBgColorDisabled: attributes.textBgColorDisabled,
      textForeColorDisabled: attributes.textForeColorDisabled,
      bgColor: attributes.bgColor,
      foreColor: attributes.foreColor,
      fontSize: this.getFontSize(attributes.fontSize),
      textFontSize: this.getFontSize(attributes.textFontSize),
      tooltipForeColor: attributes.tooltipForeColor,
      width: attributes.width && this.convertUnits(attributes.width),
      height: attributes.height && this.convertUnits(attributes.height),
      size: attributes.size,
      fixedText: attributes.fixedText === "true",
      labelWidth:
        attributes.type === "TL" && attributes.width
          ? "100%"
          : this.convertUnits(attributes.labelwidth ?? 8),
      fieldSize: this.convertUnits(attributes.fieldSize ?? 12),
      lines: this.convertUnits(attributes.lines ?? "auto", true),
      multiLine: Number(attributes.lines ?? 1) !== 1,
      image: this.getImage(attributes.image),
      keepAspectRatio: attributes.keepAspectRatio === "true",
      borderCornerRadius: `${attributes.borderCornerRadius ?? 2}px`,
      borderWidth: `${attributes.borderWidth ?? 1}px`,
      disableEdit: attributes.disableEdit,
      disableVisible: attributes.disableVisible,
      locked: attributes.locked === "true",
      method: attributes.method,
      onClick: attributes.onClick,
      onTextChanged: attributes.onTextChanged,
      onFocusChanged: attributes.onFocusChanged,
      contents: attributes.contents,
      editInRow: attributes.editInRow === "true",
      readOnly: (attributes.readOnly ?? "true") === "true",
      floating: this.getFloating(attributes),
      viewMode: attributes.viewMode,
      galleryColumns: Number(attributes.galleryColumns ?? 1),
      cellOddColor: attributes.cellOddColor ?? "transparent",
      cellEvenColor: attributes.cellEvenColor ?? "transparent",
      autoslideDelay:
        !isNaN(Number(attributes.autoslideDelay)) &&
        Number(attributes.autoslideDelay),
      tooltipColor: attributes.tooltipColor,
      linkedTo: attributes.linkedTo,
      linkedField: attributes.linkedField,
      calendarViewMode: attributes.calendarViewMode,
      cellBgColor: attributes.cellBgColor,
      cellForeColor: attributes.cellForeColor,
      cellBorderColor: attributes.cellBorderColor,
      cellSelectedBgColor: attributes.cellSelectedBgColor,
      cellSelectedForeColor: attributes.cellSelectedForeColor,
      cellSelectedBorderColor: attributes.cellSelectedBorderColor,
      cellOtherMonthBgColor: attributes.cellOtherMonthBgColor,
      weekdaysLongname: attributes.weekdaysLongname === "true",
      fontBold: attributes.fontBold === "true",
      imgChecked: this.getImage(attributes.imgChecked),
      imgUnchecked: this.getImage(attributes.imgUnchecked),
      imgCheckedDisabled: this.getImage(attributes.imgCheckedDisabled),
      imgUncheckedDisabled: this.getImage(attributes.imgUncheckedDisabled),
      imgWidth: this.convertUnits(attributes.imgWidth),
      imgHeight: this.convertUnits(attributes.imgHeight),
      autosave: attributes.autosave === "true",
      href: attributes.href,
      onEditorAction: attributes.onEditorAction,
      buttonOption: attributes.buttonOption,
      paddings: this.getPaddings(attributes),
      showInline: attributes.showInline === "true",
      showInlineKeyboard: attributes.showInlineKeyboard === "true",
      postOnchange: attributes.postOnchange,
      labelBox: attributes.labelBox === "true",
      borderColor: attributes.borderColor ?? "black",
      elevation: attributes.elevation,
      breadcumbForeColor: attributes.breadcumbForeColor,
      breadcumbFontSize: this.getFontSize(attributes.breadcumbFontSize),
      breadcumbSelectedForeColor: attributes.breadcumbSelectedForeColor,
      breadcumbSelectedFontSize: this.getFontSize(
        attributes.breadcumbSelectedFontSize
      ),
      isRadio: attributes.checkType === "radio",
      radioGroup: attributes.radioGroup,
    };
  }

  /**
   * Attributes for containers
   * @param {Object} attributes
   * @returns {ContainerAttributes}
   */
  getContainerAttributes(attributes) {
    return {
      node: attributes.node,
      name: attributes.name,
      id: attributes.id,
      margins: this.getMargins(attributes),
      tMargin: attributes.tMargin,
      rMargin: attributes.rMargin,
      bMargin: attributes.bMargin,
      lMargin: attributes.lMargin,
      borders: this.getBorders(attributes),
      bgColor: attributes.bgColor,
      foreColor: attributes.foreColor,
      width: attributes.width && this.convertUnits(attributes.width),
      height:
        (attributes.height && this.convertUnits(attributes.height)) || "auto",
      fixed: attributes.fixed ?? "",
      orientation: attributes.orientation ?? "",
      newLine: (attributes.newLine ?? "true") === "true",
      align: this.getContainerAlign(attributes),
      noTab: attributes.noTab === "true",
      tabFontSize: this.getFontSize(attributes.tabFontSize),
      tabForecolor: attributes.tabForecolor ?? "black",
      tabSelectedForecolor: attributes.tabSelectedForecolor ?? "#3273dc",
      tabBgColor: attributes.tabBgColor ?? "white",
      tabPaddings: this.getTabPaddings(attributes),
      paddings: this.getPaddings(attributes),
      image: this.getImage(attributes.image),
      keepAspectRatio: attributes.keepAspectRatio === "true",
      borderCornerRadius: `${attributes.borderCornerRadius ?? 2}px`,
      borderWidth: `${attributes.borderWidth ?? 1}px`,
      disableVisible: attributes.disableVisible,
      drawerOrientation: attributes.drawerOrientation,
      scroll: attributes.scroll === "true",
      scrollOrientation: attributes.scrollOrientation ?? "vertical",
      floating: this.getFloating(attributes),
      cellOddColor: attributes.cellOddColor ?? "transparent",
      cellEvenColor: attributes.cellEvenColor ?? "transparent",
      loadAll: attributes.loadAll === "true",
      framebox: attributes.framebox === "true",
      onFocus: attributes.onFocus,
      viewMode: attributes.viewMode,
      href: attributes.href,
      cellSelectedBgColor: attributes.cellSelectedBgColor,
      animationIn: this.getAnimation(attributes.animationIn),
      animationOut: this.getAnimation(attributes.animationOut),
      borderColor: attributes.borderColor ?? "black",
      elevation: attributes.elevation,
    };
  }

  /**
   * Execute method node (method, onfocus ...)
   * @param {string} method
   * @param {XoneDataObject} xoneDataObject
   * @param {string} [propName]
   * @returns {Promise<boolean>}
   */
  async executeMethod(method, xoneDataObject, propName) {
    try {
      const lowerCaseMethod = method?.trim().toLowerCase();

      // Remove 'javascript:' if  exists
      if (lowerCaseMethod.startsWith("javascript:"))
        method = method.trim().substring(11);

      //
      // Is not executeNode
      if (!lowerCaseMethod.includes("executenode")) {
        // Refresh
        if (lowerCaseMethod.startsWith("refresh")) {
          if (!propName) return;
          return xoneDataObject.DoRunScriptAsync(`ui.refresh('${propName}')`);
        }
        // RunScript
        return xoneDataObject.DoRunScriptAsync(method);
      }

      //
      // Is executeNode

      // Parse method
      let params;
      method = method
        .substring(method.indexOf("("))
        .replace("(", "")
        .replace(")", "");

      // with params
      if (method.includes("(") && method.includes(")")) {
        params = method.substring(method.indexOf("("));
        method = method.replace(params, "");
        params = params
          .replace("(", "")
          .replace(")", "")
          .split(",");
        params = params.map((param) =>
          !param.toString().startsWith("'") &&
          !param.toString().startsWith('"') &&
          !param.toString().startsWith("'") &&
          !param.toString().startsWith('"')
            ? `'${param}'`
            : param
        );

        await xoneDataObject.ExecuteNode(method, ...params);
      }
      // without params
      else {
        await xoneDataObject.ExecuteNode(method);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * Evaluate disableedit / disablevisible
   * @param {string} formula
   * @param {Object} dataModel
   */
  evalFormula(formula, dataModel) {
    try {
      /**
       * Eval Function
       * @type {Function}
       */
      let fFunction = this._mapFormulaFunctions.get(formula);

      // Function exist -> execute and return result
      if (fFunction) return fFunction(dataModel);

      // Function does not exist -> parse formula (from XOne to javascript) and create function
      let st = formula;

      const operators = [
        // xone conditions
        { from: "+", to: " + " },
        { from: "-", to: " - " },
        { from: "/", to: " / " },
        { from: "*", to: " * " },
        { from: "&", to: " + " },
        { from: " and ", to: " && " },
        { from: " or ", to: " || " },
        { from: "<>", to: " != " },
        { from: ">", to: " > " },
        { from: "<", to: " < " },
      ];
      // returns if formula includes an oper
      const includeOperator = () => {
        let isIncluded = false;
        operators.forEach((e) => {
          if (e.from == "=") return;
          if (st.toLowerCase().includes(e.from)) {
            isIncluded = true;
          }
        });
        return isIncluded;
      };

      const operatorsOrder = [];

      // Parse opers
      operators.forEach((e) => {
        while (includeOperator()) {
          const index = st.toLowerCase().indexOf(e.from);
          if (index === -1) return;
          operatorsOrder.push({
            index,
            oper: e.to,
          });
          st = st.replace(
            st.substr(index, e.from.length),
            Array(e.from.length + 1).join("~") // Replace operator's chars with ~
          );
        }
      });

      // Parse  opers '='
      while (st.includes("=")) {
        const index = st.indexOf("=");
        operatorsOrder.push({
          index,
          oper: " == ",
        });
        st = st.replace("=", "~"); // Replace = with ~
      }

      // Clear extra chars
      while (st.includes("~~")) st = st.replace("~~", "~");
      const order = operatorsOrder.map((e) => e.index).sort((a, b) => a - b);

      // Generate the final formula to be evaluated
      let stFinal = "";
      let i = 0;
      st.split("~").forEach((e) => {
        if (stFinal !== "" && i < order.length) {
          stFinal += operatorsOrder.find((e) => e.index === order[i]).oper;
          i++;
        }
        let prop = e;
        if (Object.keys(dataModel).find((e) => e === prop)) {
          // Replace prop
          prop = `self['${prop}']`;
        }
        stFinal += prop;
      });

      // Replace macros ##FLD_ ##
      if (stFinal.includes("##FLD_"))
        stFinal = stFinal.replace(/##FLD_/g, "self['").replace(/##/g, "']");

      // create function
      this._mapFormulaFunctions.set(
        formula,
        (fFunction = Function("self", `return ${stFinal}`))
      );

      // eval formula
      return fFunction(dataModel);
    } catch (ex) {
      console.error("Error evaluating formula", formula, ex);
    }
  }

  /**
   * Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
   * @param {Ref<Object>} attributes
   * @param {Ref<Object>} attributesModel
   * @param {Ref<Object>} dataModel
   */
  watchAttributes(attributes, attributesModel, dataModel) {
    Object.keys(attributesModel.value).forEach((e) => {
      watchEffect(() => {
        attributes.value[e] = attributesModel.value[e];

        // Check ##FLD_ value
        if (
          !attributesModel.value[e] ||
          !attributesModel.value[e].toString().includes("##FLD_") ||
          e === "disableVisible" ||
          e === "disableEdit"
        )
          return;

        let st = attributesModel.value[e].toString();
        while (st.includes("##FLD")) {
          // Foreign key
          const foreignKey = st.substring(
            st.indexOf("##FLD_") + 6,
            st.indexOf("##", st.indexOf("##") + 6)
          );

          // Resolve ##FLD_
          if (foreignKey && dataModel[foreignKey]) {
            st = st.replace(`##FLD_${foreignKey}##`, dataModel[foreignKey]);
            attributes.value[e] = st;
          } else return;

          // Resolve attribute
          if (attributesModel.value[e].node === "prop")
            attributes.value[e] = this.getPropAttributes(attributes.value[e]);
          else if (
            attributesModel.value[e].node === "group" ||
            attributes.value[e].node === "frame"
          )
            attributes.value[e] = this.getContainerAttributes(
              attributes[e].value
            );
        }
      });
    });
  }

  /**
   * executeBindedEvent
   * @param {XoneDataObject} xoneDataObject
   * @param {Object} attributes
   * @param {string} type
   * @param {Object} options
   */
  async executeBindedEvent(xoneDataObject, attributes, type, options) {
    try {
      const objView = getView(xoneDataObject);
      if (!objView) return;
      const event = objView.bindedEvents?.find(
        (e) => e.fieldName === attributes.name && e.eventName === type
      );

      if (event && event.action) {
        if (typeof event.action === "function") {
          // Add params
          const params = {
            target: attributes.name,
            objItem: attributes.name,
            data: event.params,
          };

          Object.keys(options).forEach((key) => (params[key] = options[key]));
          // Execute function
          await event.action(params);
        }
        // TODO: revisar, creo que se ejecuta un nodo pero realmente no tengo ni idea de lo que debe de hacer eso...
        else xoneDataObject.executeNode(event.action).catch(console.error);
      }
    } catch (ex) {
      console.error(ex);
    }
  }
}

export const xoneAttributesHandler = new XoneAttributesHandler();
