import { XoneApplication } from "./appData/core/XoneApplication";
import XmlNode from "./appData/Xml/JSONImpl/XmlNode";
import { generateUniqueId } from "./helperFunctions/StringHelper";

/**
 * @typedef {Object} AppSize
 * @property {number} width
 * @property {number} height
 */

/**
 * Handler App node Class
 */
class AppHandler {
  /**
   * _instance
   * @type{AppHandler}
   */
  static _instance;

  /**
   * appData
   * @type {XoneApplication}
   */
  _appData;

  /**
   * appNode
   * @type {XmlNode}
   */
  _appNode;

  /**
   * orientation
   * @type {string}
   */
  _orientation;

  /**
   * sizeConditions
   * @type {string}
   */
  _sizeConditions;

  constructor() {
    if (AppHandler._instance) return AppHandler._instance;
    AppHandler._instance = this;
  }

  /**
   * @param {XoneApplication} appData
   */
  setAppData(appData) {
    this._appData = appData;
    this.setAppNode();
  }

  /** @returns {string} */
  get orientation() {
    return this._orientation;
  }

  /** @returns {string} */
  get sizeConditions() {
    return this._sizeConditions;
  }

  // Set app node
  setAppNode() {
    this._appNode = this._appData.getAppNode();
  }

  // Get App width / height

  /**
   * Get App Size
   * @returns {AppSize}
   */
  getAppSize() {
    const width = this._appNode.getAttrValue("resolution-width");
    const height = this._appNode.getAttrValue("resolution-height");

    if (isNaN(width) || isNaN(height)) {
      console.error(
        "resolution-width and resolution-height must be numeric in <app></app>"
      );
      return { width: null, height: null };
    }
    return {
      width: Number(width),
      height: Number(height),
    };
  }

  /**
   * Set Visual Conditions / Visual Macros
   * @param {Number} width
   * @param {Number} height
   */
  setVisualConditionsMacros(width, height) {
    // Width / Height
    this._appData.setGlobalMacro(
      "##SCREEN_RESOLUTION_WIDTH##",
      width.toString()
    );
    this._appData.setGlobalMacro(
      "##SCREEN_RESOLUTION_HEIGHT##",
      width.toString()
    );
    // Orientation
    this._orientation = width > height ? "horizontal" : "vertical";
    this._appData.setGlobalMacro("##CURRENT_ORIENTATION##", this._orientation);
    // VisualConditions
    this._sizeConditions =
      width < 480 ? "xs" : width < 768 ? "s" : width < 1024 ? "md" : "lg";
    this._appData.setVisualConditions(this._sizeConditions, false);
  }

  /**
   * Generate a GUID as a DeviceId
   * @returns {string}
   */
  getDeviceId() {
    let deviceId = localStorage.getItem("_deviceId");
    if (!deviceId)
      localStorage.setItem(
        "_deviceId",
        (deviceId = Array(5).join(generateUniqueId()))
      );
    return deviceId;
  }

  /**
   * Set appData inmutable Macros
   */
  setInmutableMacros() {
    const attributes = this._appNode.attrs;

    if (!attributes) {
      console.error("Not found <app></app>");
      return;
    }

    const deviceId = this.getDeviceId();
    const [language, countryCode] = Intl.DateTimeFormat()
      .resolvedOptions()
      .locale.split("-");
    const { platform, vendor } = navigator;

    this._appData.setGlobalMacro("##DEVICE_OS##", "web");
    this._appData.setGlobalMacro("##DEVICE_OSVERSION##", platform);
    this._appData.setGlobalMacro("##DEVICE_MODEL##", vendor);
    this._appData.setGlobalMacro("##VERSION##", attributes?.version ?? "1");
    this._appData.setGlobalMacro("##DEVICEID##", deviceId);
    this._appData.setGlobalMacro("##CURRENT_LANGUAGE##", language);
    this._appData.setGlobalMacro("##CURRENT_COUNTRY_CODE##", countryCode);
    this._appData.setGlobalMacro("##FRAME_VERSION##", "v1");

    this._appData.setGlobalMacro("##LIVEUPDATE_VERSION##", "0");
    this._appData.setGlobalMacro("##DEVICE_MANUFACTURER##", "web");
    this._appData.setGlobalMacro("##DEVICE_TYPE##", "web");
    this._appData.setGlobalMacro("##MID##", "999");
    this._appData.setGlobalMacro("##DEVICE_OSVERSION##", "web");
    this._appData.setGlobalMacro("##CURRENT_DENSITY##", "1");
    this._appData.setGlobalMacro("##CURRENT_DENSITY_VALUE##", "1");
  }

  /**
   * Get autologon attribute
   * @returns {boolean}
   */
  getAutoLogon() {
    let autoLogon = this._appNode.attrs.getValue("autologon");
    return autoLogon === "true";
  }

  // getEntryPoint() {
  //   return this.getAppValueWithConditions("entry-point");
  // }

  // getLoginColl() {
  //   return this.getAppValueWithConditions("login-coll");
  // }

  getWebLayout() {
    return this.getAppValueWithConditions("web-layout");
  }

  getAppValueWithConditions(attr) {
    try {
      // get login-coll from attribute
      let attribute = this._appNode.getAttrValue(attr);

      // get appNode
      const app = this._appNode.toJSON().app;

      // get login-coll node
      const attributeNode = app.childs.find((e) => e[attr]);
      if (!attributeNode || !attributeNode[attr]) return attribute;

      // login-coll items
      const items = attributeNode[attr].childs
        .map((e) => e.item)
        .filter((e) => {
          const conditions = e.attributes.conditions;
          return conditions === "" || conditions.includes("web");
        });

      // filter size conditions
      let filteredItems = items.filter((e) => {
        const conditions = e.attributes.conditions.split(":");
        return conditions.includes(this.sizeConditions);
      });

      // filter orientation
      filteredItems = (filteredItems.length !== 0 || items).filter((e) => {
        const conditions = e.attributes.conditions.split(":");
        return conditions.includes(this._orientation);
      });

      return (filteredItems.length !== 0 || items)[0].attributes.name;
    } catch (ex) {
      console.error(`Error in getAppValueWithConditions: ${ex}`);
    }
  }
}

export default new AppHandler();
