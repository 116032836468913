<template>
  <teleport v-if="isLoaderVisible" to="body">
    <div class="xone-loader-transition">
      <div></div>
    </div>
  </teleport>
</template>

<script>
import { nextTick, ref } from "vue";
import {
  setHideLoaderCallback,
  setShowLoaderCallback,
} from "../../composables/XoneUI";
export default {
  setup() {
    const isLoaderVisible = ref(false);

    setShowLoaderCallback(async () => {
      isLoaderVisible.value = true;
      await nextTick();
    });
    setHideLoaderCallback(async () => {
      isLoaderVisible.value = false;
      await nextTick();
    });

    return { isLoaderVisible };
  },
};
</script>

<style scoped>
.xone-loader-transition {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s;
  z-index: 999;
}

.xone-loader-transition div:first-child {
  border-radius: 50%;
  border: 8px solid white;
  border-left: 8px solid transparent;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
}
</style>