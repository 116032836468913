export const UI_ATTRIBUTES = {
  coll: {
    name: "name",
    cellOddColor: "cell-odd-color",
    cellEvenColor: "cell-even-color",
    loadAll: "loadall",
    cellSelectedBgColor: "cell-selected-bgcolor",
    noTab: "notab",
    tabSelectedForecolor: "tab-selected-forecolor",
    tabFontSize: "tab-fontsize",
    tabForecolor: "tab-forecolor",
    tabTPadding: "tab-tpadding",
    tabRPadding: "tab-rpadding",
    tabBPadding: "tab-bpadding",
    tabLPadding: "tab-lpadding",
    tabBgColor: "tab-bgcolor",
  },
  group: {
    name: "name",
    id: "id",
    tMargin: "tmargin",
    rMargin: "rmargin",
    bMargin: "bmargin",
    lMargin: "lmargin",
    bgColor: "bgcolor",
    foreColor: "forecolor",
    width: "width",
    height: "height",
    fixed: "fixed",
    orientation: "orientation",
    align: "align",
    image: { value: ["imgbk", "img", "image", "path"] },
    keepAspectRatio: "keep-aspect-ratio",
    borderCornerRadius: "border-corner-radius",
    drawerOrientation: "drawer-orientation",
    onFocus: "onfocus",
    elevation: "elevation",
    tPadding: "tpadding",
    rPadding: "rpadding",
    bPadding: "bpadding",
    lPadding: "lpadding",
  },
  frame: {
    name: "name",
    tMargin: "tmargin",
    rMargin: "rmargin",
    bMargin: "bmargin",
    lMargin: "lmargin",
    bgColor: "bgcolor",
    foreColor: "forecolor",
    width: "width",
    height: "height",
    newLine: "newline",
    align: "align",
    image: { value: ["imgbk", "img", "image", "path"] },
    keepAspectRatio: "keep-aspect-ratio",
    borderCornerRadius: "border-corner-radius",
    borderWidth: "border-width",
    disableVisible: "disablevisible",
    scroll: "scroll",
    scrollOrientation: "scroll-orientation",
    floating: "floating",
    top: "top",
    left: "left",
    framebox: "framebox",
    mapCol: "mapcol",
    mapFld: "mapfld",
    viewMode: "viewmode",
    href: "href",
    animationIn: "animation-in",
    animationOut: "animation-out",
    borderColor: "border-color",
    elevation: "elevation",
    tPadding: "tpadding",
    rPadding: "rpadding",
    bPadding: "bpadding",
    lPadding: "lpadding",
  },
  prop: {
    name: "name",
    type: "type",
    title: { value: ["title", "caption"] },
    visible: "visible",
    tMargin: "tmargin",
    rMargin: "rmargin",
    bMargin: "bmargin",
    lMargin: "lmargin",
    newLine: "newline",
    textBorder: "text-border",
    textBorderTop: "text-border-top",
    textBorderRight: "text-border-right",
    textBorderBottom: "text-border-bottom",
    textBorderLeft: "text-border-left",
    floatingTooltip: "floating-tooltip",
    tooltip: "tooltip",
    tooltipColor: {
      value: ["expanded-hint-color", "floating-tooltip-color"],
    },
    align: "align",
    textAlign: "text-align",
    textBgColor: "text-bgcolor",
    // "text-forecolor": "text-forecolor",
    textForeColor: {
      default: "##COMPANYFORECOLOR##",
      value: ["text-forecolor", "textfore-color", "forecolor"],
    },
    method: "method",
    onClick: "onclick",
    bgColor: "bgcolor",
    foreColor: "forecolor",
    contents: "contents",
    // forecolor: {
    //   default: "##COMPANYFORECOLOR##",
    //   value: ["label-forecolor", "labelfore-color", "forecolor"],
    // },
    tooltipForecolor: "tooltip-forecolor",
    width: "width",
    height: "height",
    labelwidth: "labelwidth",
    fieldSize: "fieldsize",
    lines: "lines",
    image: { value: ["img", "image", "path", "imgbk"] },
    keepAspectRatio: "keep-aspect-ratio",
    borderCornerRadius: "border-corner-radius",
    disableEdit: "disableedit",
    disableVisible: "disablevisible",
    locked: "locked",
    editInRow: "edit-inrow",
    readOnly: "readonly",
    borderWidth: "border-width",
    floating: "floating",
    top: "top",
    left: "left",
    fontSize: "fontsize",
    textFontSize: "text-fontsize",
    viewMode: "viewmode",
    calendarViewMode: "calendar-viewmode",
    galleryColumns: "gallery-columns",
    cellOddColor: "cell-odd-color",
    cellEvenColor: "cell-even-color",
    mask: "mask",
    autoslideDelay: "autoslide-delay",
    onTextChanged: "ontextchanged",
    onFocusChanged: "onfocuschanged",
    linkedTo: "linkedto",
    linkedField: "linkedfield",
    cellBgColor: "cell-bgcolor",
    cellForeColor: "cell-forecolor",
    cellBorderColor: "cell-border-color",
    cellSelectedBgColor: "cell-selected-bgcolor",
    cellSelectedForeColor: "cell-selected-forecolor",
    cellSelectedBorderColor: "cell-selected-border-color",
    cellOtherMonthBgColor: "cell-other-month-bgcolor",
    weekdaysLongname: "weekdays-longname",
    fontBold: "fontbold",
    imgChecked: "img-checked",
    imgUnchecked: "img-unchecked",
    imgCheckedDisabled: "img-checked-disabled",
    imgUncheckedDisabled: "img-unchecked-disabled",
    imgWidth: "img-width",
    imgHeight: "img-height",
    autosave: "autosave",
    href: "href",
    onEditorAction: "oneditoraction",
    textForeColorDisabled: "text-forecolor-disabled",
    textBgColorDisabled: "text-bgcolor-disabled",
    buttonOption: "button-option",
    tPadding: "tpadding",
    rPadding: "rpadding",
    bPadding: "bpadding",
    lPadding: "lpadding",
    showInline: "showinline",
    showInlineKeyboard: "showinline-keyboard",
    postOnchange: "postonchange",
    labelBox: "labelbox",
    borderColor: "border-color",
    fixedText: "fixed-text",
    size: "size",
    elevation: "elevation",
    breadcumbForeColor: "breadcumb-forecolor",
    breadcumbFontSize: "breadcumb-fontsize",
    breadcumbSelectedForeColor: "breadcumb-selected-forecolor",
    breadcumbSelectedFontSize: "breadcumb-selected-fontsize",
    checkType: "check-type",
    radioGroup: "radio-group",
  },
};
export const COLOR_ATTRIBUTES = [
  "foreColor",
  "bgColor",
  "textForeColor",
  "textBgColor",
  "cellBgColor",
  "cellForeColor",
  "cellBorderColor",
  "cellSelectedBgColor",
  "cellSelectedForeColor",
  "cellSelectedBorderColor",
  "cellOtherMonthBgColor",
  "textForeColorDisabled",
  "textBgColorDisabled",
];
export const FAST_ATTR_RESOLVER = (() => {
  const obj = {};
  const fetchFastAttrResolver = ([normalized, original]) => {
    if (typeof normalized === "object" && normalized.value)
      normalized = normalized.value[0];
    if (typeof original === "object" && original.value)
      return original.value.forEach((e) => (obj[e] = normalized));
    obj[original] = normalized;
  };
  Object.entries(UI_ATTRIBUTES.coll).forEach(fetchFastAttrResolver);
  Object.entries(UI_ATTRIBUTES.frame).forEach(fetchFastAttrResolver);
  Object.entries(UI_ATTRIBUTES.prop).forEach(fetchFastAttrResolver);
  return obj;
})();
