<template>
  <div
    v-if="routeBreadcumbs && routeBreadcumbs.length !== 0"
    class="xone-breadcumb"
  >
    <template v-for="breadcumb in routeBreadcumbs" :key="breadcumb.id"
      ><div class="xone-breadcumb-item">
        <div
          v-if="breadcumb !== routeBreadcumbs[0]"
          :style="{ fontSize: attributes.breadcumbFontSize }"
        >
          &nbsp;&nbsp;/&nbsp;&nbsp;
        </div>
        <div
          @click="onBreadcumbClick(breadcumb)"
          :style="{
            color: getBreadcumbColor(breadcumb),
            fontSize: getBreadcumbFontSize(breadcumb),
            cursor: breadcumb !== lastBreadcumb ? 'pointer' : 'auto',
          }"
        >
          {{ breadcumb.title || breadcumb.name }}
        </div>
      </div></template
    >
  </div>
</template>

<script>
import { inject, ComputedRef, PropType } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Breadcumb",
  props: {
    /**
     * attributes
     * @type { PropType<import('../../composables/XoneAttributesHandler').PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
  },
  setup(props) {
    const router = useRouter();

    /**
     * @type {ComputedRef<Breadcumb[]>}
     */
    const routeBreadcumbs = inject("routeBreadcumbs");

    /**
     * @type {ComputedRef<import('../../composables/AppDataHandler').Breadcumb>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    return {
      routeBreadcumbs,
      lastBreadcumb,
      onBreadcumbClick: (breadcumb) => {
        router.push({
          name: "Coll",
          query: { id: breadcumb.id },
        });
      },
      getBreadcumbColor: (breadcumb) => {
        return breadcumb === lastBreadcumb.value
          ? props.attributes.breadcumbSelectedForeColor ||
              props.attributes.breadcumbForeColor
          : props.attributes.breadcumbForeColor;
      },
      getBreadcumbFontSize: (breadcumb) => {
        return breadcumb === lastBreadcumb.value
          ? props.attributes.breadcumbSelectedFontSize ||
              props.attributes.breadcumbFontSize
          : props.attributes.breadcumbFontSize;
      },
    };
  },
};
</script>

<style scoped>
.xone-breadcumb,
.xone-breadcumb-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>