import { ref, Ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import { XoneDataCollection } from "./appData/core/XoneDataCollection";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { XoneApplication } from "../composables/appData/core/XoneApplication";

import { generateUniqueId } from "./helperFunctions/StringHelper";
import xoneUI from "./XoneUI";
import XmlNode from "./appData/Xml/JSONImpl/XmlNode";

//
// Add methods to XoneDataObject prototype

XoneDataObject.prototype.changeModelValue = function(propName, newValue) {
  this[propName] = newValue;
};

XoneDataObject.prototype.clone = function() {
  const newObj = new XoneDataObject(this.getOwnerCollection());
  Object.entries(this).forEach(([key, value]) => (newObj[key] = value));
  newObj["_objCloned"] = this;
  newObj["_XoneHashId"] = undefined;
  newObj["_XoneReactive"] = undefined;
  //newObj.model = Object.assign({}, newObj.model);
  newObj.getObjectIndex = () => this.getObjectIndex();
  return newObj;
};

XoneDataObject.prototype.doModelReactive = function() {
  if (this["_XoneReactive"]) return;

  this.model = ref(this.model).value;
  this["_XoneReactive"] = true;

  // Si el objeto está clonado, propagamos el cambio
  if (!this["_objCloned"] || !this["_objCloned"]["_XoneReactive"]) return;
  Object.keys(this.model).forEach((key) =>
    watch(
      () => this.model[key],
      (newValue) => this["_objCloned"].changeModelValue(key, newValue)
    )
  );
};

XoneDataObject.prototype.isReactive = function() {
  return this["_XoneReactive"];
};

XoneDataObject.prototype.getHashId = function() {
  if (!this["_XoneHashId"])
    this["_XoneHashId"] = Array(3).join(generateUniqueId());
  return this["_XoneHashId"];
};

//
// Add methods to XoneApplication prototipes

XoneApplication.prototype.exit = () => xoneUI.exitApp();

XoneApplication.prototype.exitApp = () => {
  window.close();
  xoneUI.exitApp();
};

XoneApplication.prototype.logout = () => xoneUI.exitApp();

XoneApplication.prototype.pushValueAndExit = (arg) =>
  xoneUI.openEditViewAndExit(arg);

/**
 * @typedef {Object} Breadcumb
 * @property {string} id
 * @property {string} xoneHashId
 * @property {string} type
 * @property {string} name
 * @property {string} title
 * @property {boolean} isWebLayout
 * @property {boolean} isMsgbox
 */

/**
 * Nos da informacion del objeto
 * @typedef {Object} Objectinfo
 * @property {boolean} isContents
 * @property {number} recordIndex
 * @property {boolean} editInRow
 * @property {boolean} autosave
 * @property {function(number): void} onMsgBoxOptionSelected
 */

/** Class to handle appData, dataObjectsMap and breadcumb stack */
class AppDataHandler {
  /**
   * _instance
   * @type {AppDataHandler}
   */
  static _instance;

  /**
   * appData
   * @type {XoneApplication}
   */
  _appData;

  // Separamos los breadcumb de los dataObjects dado que no se puede hacer un stringify de objetos con estructura circular, así que no lo podemos expandir a los componentes hijo
  // queda con los dataobjects en la propiedad _dataObjectsMap y el indice de breadcumbs en _breadcumbs

  /**
   * dataObjectsMap
   * @type {Map<string, XoneDataObject>}
   */
  _dataObjectsMap = new Map();

  /**
   * breadcumbs
   * @type {Ref<Breadcumb[]>}
   */
  _breadcumbs = ref([]);

  constructor() {
    if (AppDataHandler._instance) return AppDataHandler._instance;
    AppDataHandler._instance = this;
  }

  /**
   * Set AppData
   * @param {XoneApplication} appData
   */
  setAppData(appData) {
    this._appData = appData;
  }

  /**
   * Get AppData
   * @returns {XoneApplication}
   */
  getAppData() {
    return this._appData;
  }

  /**
   * Get breadcumbs
   * @returns {Breadcumb[]}
   */
  getBreadcumbs() {
    return this._breadcumbs.value;
  }

  /**
   * Set DataObject
   * @param {string} id
   * @param {XoneDataObject} value
   */
  addDataObject(id, value) {
    this._dataObjectsMap.set(id, value);
  }

  /**
   * Get XOneDataObject from breadcumb id
   * @param {string} id
   * @returns {XoneDataObject}
   */
  getDataObject(id) {
    return this._dataObjectsMap.get(id);
  }

  /**
   * get XOneDataObjects
   * @returns {XoneDataObject[]}
   */
  getDataObjects() {
    return Array.from(this._dataObjectsMap.values());
  }

  /**
   * Clear dataObjectsMap
   */
  cleardataObjectsMap() {
    this._dataObjectsMap.clear();
  }

  /**
   * Clear Breadcumbs
   * @param {boolean} [keepLayout ]
   */
  clearBreadcumbs(keepLayout = false) {
    // Remove breadcumbs
    this._breadcumbs.value = keepLayout
      ? this._breadcumbs.value.filter((e) => e.isWebLayout)
      : [];
    // Remove dataobjects
    this.cleardataObjectsMap();
  }

  /**
   * Remove breadcumb from breadcumb id
   * @param {string} id
   */
  removeBreadcumb(id) {
    const breadcumb = this._breadcumbs.value.find((e) => e.id === id);
    if (!breadcumb) return;

    const index = this._breadcumbs.value.indexOf(breadcumb);
    // Remove breadcumb
    this._breadcumbs.value.splice(index, 1);
    // Remove dataobject
    this._dataObjectsMap.delete(id);
  }

  /**
   * delete last breadcumb
   */
  deleteLastBreadcumb() {
    if (this._breadcumbs.value.length === 0) return;
    const lastBreadcumb = [...this._breadcumbs.value].reverse()[0];
    this.removeBreadcumb(lastBreadcumb.id);
  }

  /**
   * Clear breadcumbs from key
   * @param {string} id
   */
  clearBreadcumbsFrom(id) {
    const breadcumb = this._breadcumbs.value.find((e) => e.id === id);
    if (!breadcumb) return;
    const index = this._breadcumbs.value.indexOf(breadcumb) + 1;
    this._breadcumbs.value.splice(index);
  }

  /**
   * clearBreadcumbsFromXoneHashId
   * @param {string} xoneHashId
   */
  clearBreadcumbsFromXoneHashId(xoneHashId) {
    this._breadcumbs.value = this._breadcumbs.value.filter(
      (e) => e.xoneHashId !== xoneHashId
    );
  }

  /**
   * Create new XoneDataObject and push it into dataObjectsMap Map
   * @param {string} collName
   * @param {string} [objectType]
   * @param {boolean} [isWebLayout]
   * @returns {Promise<string>}
   */
  async addNewXoneDataObject(
    collName,
    objectType = "Coll",
    isWebLayout = false
  ) {
    /**
     * Create XoneDataObject
     * @type {XoneDataCollection}
     */
    const xoneDataCollection = await this._appData.getCollection(collName);

    if (!xoneDataCollection) return;

    /**
     * xoneDataObject
     * @type {XoneDataObject}
     */
    const xoneDataObject = await xoneDataCollection.CreateObject(true);

    return this.pushXoneDataObject(xoneDataObject, objectType, isWebLayout);
  }

  /**
   * Push new XoneDataObject into dataObjectsMap Map
   * @param {XoneDataObject} xoneDataObject
   * @param {string} [objectType]
   * @param {boolean} [isWebLayout]
   * @returns {string}
   */
  async pushXoneDataObject(
    xoneDataObject,
    objectType = "Coll",
    isWebLayout = false,
    isMsgbox = false
  ) {
    const id = generateUniqueId();

    // // Si el objeto ya es reactivo, clonamos el objeto para no perder la reactividad
    // if (xoneDataObject.isReactive() && !xoneDataObject._editInRow)
    //   xoneDataObject = xoneDataObject.clone();

    /** @type {{m_xmlNode:XmlNode}} */
    const { m_xmlNode } = xoneDataObject.m_owner;

    // Set XOneDataObject
    this.addDataObject(id, xoneDataObject);

    // Push breadcumb
    this._breadcumbs.value.push({
      id,
      xoneHashId: xoneDataObject.getHashId(),
      type: objectType,
      name: xoneDataObject.m_owner.m_strName,
      title: m_xmlNode.getAttrValue("title", xoneDataObject.m_owner.m_strName),
      isWebLayout,
      isMsgbox,
    });

    return id;
  }

  /**
   * setCookie
   * @param {string} cname cookie name
   * @param {*} cvalue cookie value
   * @param {number} exdays expiration days
   */
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  /**
   * getCookie
   * @param {string} cname cookie name
   */
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  /**
   * getCircularReplacer
   */
  getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          console.warn("Cache duplicado", key, value);
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }
}

export default new AppDataHandler();
