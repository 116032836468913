<template>
  <div
    :id="`map-${breadcumbId}${objectIndex}`"
    class="xone-map"
    @click="showLayers = false"
  >
    <div class="xone-map-markers" @mousewheel="onMouseWheel">
      <div v-if="showMarkerButton" class="xone-marker-button">
        <button @click="onMarkerButtonClick"></button>
      </div>

      <transition name="slide-fade">
        <div
          class="xone-markers-list"
          v-show="showLayers"
          :style="{ maxHeight: `${controlHeight - 50}px` }"
        >
          <a
            v-for="(layer, index) in keyLayers"
            :key="`map-${index}`"
            :class="{ 'xone-selected-marker': checkIsLayerSelected(layer) }"
            @click="changeMapLayer(layer, $event)"
            >{{ layer }}</a
          >
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  Ref,
  watch,
} from "vue";

import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.min.js";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import {
  PropAttributes,
  xoneAttributesHandler,
} from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import {
  getDefaultMapLayer,
  getMapLayers,
  replaceLayerName,
} from "../../../composables/helperFunctions/MapHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import { generateUniqueId } from "../../../composables/helperFunctions/StringHelper";
import { normalizeWebColor } from "../../../composables/helperFunctions/ColorHelper";

export default {
  name: "Map",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    // const api = `https://api.locationiq.com/v1/autocomplete.php?key=47616557e9fb47&q=santander&limit=5`;

    L.Icon.Default.imagePath = "./assets/";

    // const defaultIconUrl = "/assets/marker-icon.png";
    // const defaultShadowUrl = "/assets/marker-shadow.png";

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * objectInfo
     * @type {import('../../../composables/AppDataHandler').Objectinfo}
     */
    const objectInfo = inject("objectInfo");

    const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

    // tiles provider for leaflet
    const mapLayers = getMapLayers(L, props.attributes);

    const keyLayers = computed(() =>
      Object.entries(mapLayers)
        .filter(
          ([, value]) =>
            !value.onlyLabel &&
            value.type ===
              (props.attributes.viewMode === "openstreetmap" ? "OSM" : "google")
        )
        .map(([key]) => key)
    );
    // Map
    let map;

    // Markers
    let markers = [];

    // Lines
    const polyLines = new Map();

    // Polygons
    const polygons = new Map();

    // Routes
    const routes = new Set();

    // Draw line
    const drawLine = (name, color, lineStyle, ...pointList) => {
      const points = [];
      for (let i = 0; i < pointList.length; i += 2) {
        points.push(new L.LatLng(pointList[i], pointList[i + 1]));
      }
      let dashArray = null;
      if (lineStyle === "dashed") dashArray = "10, 15";
      else if (lineStyle === "dotted") dashArray = "1, 10";
      // else if (lineStyle === "mixed") dashArray = "10, 5, 1, 10";
      const firstpolyline = new L.Polyline(points, {
        color: normalizeWebColor(color),
        weight: 5,
        opacity: 0.5,
        smoothFactor: 1,
        dashArray,
      });
      if (polyLines.get(name)) name = generateUniqueId();
      polyLines.set(name, firstpolyline);
      firstpolyline.addTo(map);
    };

    // Draw area
    const drawArea = ({ id, data, color, fillcolor, pattern, width }) => {
      const points = [];
      data.forEach((e) => {
        const dataCoords = e.split(",").map((e) => Number(e));
        points.push(new L.LatLng(dataCoords[0], dataCoords[1]));
      });
      let dashArray = null;
      if (pattern === "dashed") dashArray = "10, 15";
      else if (pattern === "dotted") dashArray = "1, 10";

      const polygon = L.polygon(points, {
        color: normalizeWebColor(color),
        fillColor: normalizeWebColor(fillcolor),
        weight: width,
        dashArray,
      }).addTo(map);

      if (polygons.get(id)) id = generateUniqueId();
      polygons.set(id, polygon);
      // polygon.bindPopup("I am a polygon.");
      // polygon.bindTooltip("I am a polygon.").openTooltip();
    };

    // Draw route
    const drawRoute = (Args) => {
      const route = L.Routing.control({
        waypoints: [
          L.latLng(Args.sourceLatitude, Args.sourceLongitude),
          L.latLng(Args.destinationLatitude, Args.destinationLongitude),
        ],
        routeWhileDragging: false,
        showAlternatives: false,
      });
      routes.add(route);
      route.addTo(map);
    };

    // Remove markers
    const removeMarkers = () => {
      markers.forEach((e) => map.removeLayer(e));
      markers = [];
    };

    // Remove lines
    const clearLines = () => {
      polyLines.forEach((e) => map.removeLayer(e));
      polyLines.clear();
    };

    // Remove lines
    const clearAreas = () => {
      polygons.forEach((e) => map.removeLayer(e));
      polygons.clear();
    };

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    /**
     * contents data
     * @type {Ref<Array<XoneDataObject>>}
     */
    const data = ref([]);

    /**
     * Refresh map
     */
    const refresh = async () => {
      // Load map
      loadMap();

      // Get contents
      if (!contents.value)
        contents.value = await props.xoneDataObject.getContents(
          props.attributes.contents
        );

      // // Get coll attributes
      // const contentsAttributes = xoneAttributesHandler.getContainerAttributes(
      //   contents.value.getLayout().attributes
      // );

      // Load contents data
      // LoadAll
      await contents.value.loadAll(false);

      data.value = [];
      for (let i = 0; i < contents.value.length; i++) {
        /**
         * rowDataObject
         * @type {XoneDataObject}
         */
        const rowDataObject = await contents.value.get(i);
        data.value.push(rowDataObject);
        // Execute Nodes
        rowDataObject
          .ExecuteNode("before-edit")
          .then(() => rowDataObject.ExecuteNode("load"))
          .then(() => rowDataObject.ExecuteNode("after-edit"))
          .catch(console.error);
      }

      drawMarkers(true);
    };

    /**
     * Refresh map markers
     */
    const drawMarkers = (withFitBounds = false) => {
      // create bounds array
      const bounds = [];
      data.value.forEach((e) => {
        if (!e.LATITUD || !e.LONGITUD) return;

        let iconUrl = `/assets/marker-icon.png`;
        // TODO: poner el  icono que viene del mappings

        const icon = L.icon({
          iconUrl,
          shadowUrl: "/assets/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        });

        // push coordinates into bounds
        bounds.push([e.LATITUD, e.LONGITUD]);
        // Create marker
        const marker = L.marker([e.LATITUD, e.LONGITUD], { icon }) //, { icon }
          .addTo(map)
          .on("click", async () => {
            // on click execute selecteditem node
            try {
              await e.ExecuteNode("selecteditem");
            } catch {}
          });
        markers.push(marker);
      });
      // fit bounds
      if (withFitBounds && bounds.length !== 0)
        map.fitBounds(bounds, { animate: true, duration: 1 });
      // map.flyToBounds(bounds, { animate: true, duration: 1 });
    };

    // Clear layers
    const clearLayers = () =>
      Object.keys(map._layers).forEach((key) =>
        map.removeLayer(map._layers[key])
      );

    // Clear routes
    const clearRoutes = () => routes.forEach((e) => map.removeControl(e));

    /**
     * @type {Object}
     */
    let currentMapTileLayer;

    /**
     * @type {string}
     */
    let currentMapLayerName = getDefaultMapLayer(props.attributes);

    const changeMapLayer = (
      /** @type {string}*/ layer,
      /** @type {MouseEvent} */ e = null
    ) => {
      if (e) e.stopPropagation();

      layer = replaceLayerName(layer);
      currentMapLayerName = layer;

      if (currentMapTileLayer) map.removeLayer(currentMapTileLayer);

      currentMapTileLayer = mapLayers[layer].layer;

      if (!currentMapTileLayer)
        currentMapTileLayer = Object.values(mapLayers).forEach(
          (e) => e.title === layer
        );

      // Add new layer
      currentMapTileLayer.addTo(map);

      // Set zoom
      if (map._zoom > mapLayers[layer].layer.options.maxZoom)
        map.setZoom(mapLayers[layer].layer.options.maxZoom);

      // Refresh Markers
      drawMarkers();

      showLayers.value = false;
    };

    /**
     * Load Map
     */
    const loadMap = () => {
      if (!map)
        map = L.map(`map-${breadcumbId}${objectIndex}`).setView(
          [43.454518, -3.851194],
          2
        );
      else {
        clearRoutes();
        clearLayers();
      }
      currentMapTileLayer =
        mapLayers[getDefaultMapLayer(props.attributes)].layer;
      currentMapTileLayer.addTo(map);
    };

    onMounted(() => {
      //
      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name);

      // Refresh
      xoneControl.refresh = refresh;
      // Draw Line
      xoneControl.drawLine = drawLine;
      // Clear Area
      xoneControl.clearArea = (Args) => map.removeLayer(polygons.get(Args));
      // Clear all Areas
      xoneControl.clearAllAreas = clearAreas;
      // Clear line
      xoneControl.clearLine = (Args) => map.removeLayer(polyLines.get(Args));
      // Clear all lines
      xoneControl.clearAllLines = clearLines;
      // CLear all routes
      xoneControl.clearAllRoutes = clearRoutes;
      // CLear route
      xoneControl.clearRoute = clearRoutes;
      // Draw Area
      xoneControl.drawArea = drawArea;
      // Draw Route
      xoneControl.drawRoute = drawRoute;
      xoneControl.routeTo = drawRoute;
      // Remove area
      xoneControl.removeArea = (area) =>
        polygons.has(area) && map.removeLayer(polygons.get(area));
      // Zoom to
      xoneControl.zoomTo = (...Args) => {
        map.flyTo([Args[0], Args[1]], 18, { duration: 1 });
        // map.fitBounds([
        //   [Args[0], Args[1]],
        //   [Args[0], Args[1]],
        // ]);
      };
      // Set Map Type
      xoneControl.setMapType = changeMapLayer;
      // Zoom to bounds
      xoneControl.zoomToBounds = (Args) => {
        // map.fitBounds(Args.map((e) => e.split(",").map((e) => Number(e))));
        map.flyToBounds(
          Args.map((e) => e.split(",").map((e) => Number(e))),
          { animate: true, duration: 1 }
        );
      };

      xoneView.addControl(xoneControl);

      //
      // Refresh contents
      refresh();
    });
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    const showMarkerButton = ref(true);

    const showLayers = ref(false);

    watch(
      () => showLayers.value,
      (newValue) => {
        if (newValue) showMarkerButton.value = false;
        else setTimeout(() => (showMarkerButton.value = true), 400);
      }
    );

    return {
      keyLayers,
      breadcumbId,
      objectIndex,
      showLayers,
      changeMapLayer,
      showMarkerButton,
      onMouseWheel: (/** @type{ MouseEvent} */ e) => e.stopPropagation(),
      onMarkerButtonClick: async (/** @type {MouseEvent} */ e) => {
        showLayers.value = !showLayers.value;
        e.stopPropagation();
      },
      checkIsLayerSelected: (/** @type {string} */ layer) =>
        replaceLayerName(layer) === replaceLayerName(currentMapLayerName),
    };
  },
};
</script>

<style scoped>
.xone-map {
  position: relative;
  height: 100vh;
  width: 100%;

  width: var(--contents-width);
  height: var(--contents-height);
  max-height: var(--contents-max-height);
}
.xone-map-markers {
  display: flex;
  position: absolute;
  right: 5px;
  margin-top: 5px;
  z-index: 999;
  pointer-events: all;
  padding: 5px;
}

.xone-selected-marker {
  font-weight: bold;
}

.xone-marker-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 2px solid rgb(204, 204, 204);
  background-color: #fff;
  animation: slideLeft 0.2s;

  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
}
.xone-marker-button:hover {
  background: #f4f4f4;
}

.xone-marker-button button {
  cursor: pointer;
  display: block;
  background: url(/assets/layers.png) no-repeat;
  background-position: center;
  padding: 5px;
  border: none;
  width: 30px;
  height: 30px;
  background-size: 75%;
}

.xone-markers-list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  animation: slideLeft 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border-radius: 2px;
  margin-left: 5px;
  border: 2px solid rgb(204, 204, 204);

  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
  pointer-events: all;
}

a {
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 10px;
  text-align: justify;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  pointer-events: all;
}

a:hover {
  background-color: #f4f4f4;
}

a:link {
  text-decoration: none;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>