<template>
  <div
    :id="`${attributes.name.replace('@', '')}${rowInfo.id}${breadcumbId}`"
    :class="['xone-contents-row', isSlideView && 'xone-slidesnap']"
  >
    <template v-if="isRowLoaded && xoneDataObject && control">
      <!-- contents row -->
      <Container
        v-show="rowInfo.visible"
        :xoneDataObject="xoneDataObject"
        :control="control"
        :containerHeight="controlHeight"
        :containerWidth="controlWidth"
        :visibilityBit="4"
        @click="onContentsRowClick"
        :style="{
          backgroundColor: backgroundColor,
          cursor: !attributes.editInRow && !attributes.locked && 'pointer',
        }"
      ></Container>
      <!-- expanview -->
      <template v-if="isExpanView && expanControl">
        <Container
          v-show="rowInfo.isExpanded && rowInfo.visible"
          class="xone-expanded-row"
          :xoneDataObject="xoneDataObject"
          :control="expanControl"
          :containerHeight="controlHeight"
          :containerWidth="controlWidth"
          :visibilityBit="4"
          :style="{
            backgroundColor: backgroundColor,
            cursor: !attributes.editInRow && !attributes.locked && 'pointer',
            poniterEvents: !attributes.editInRow && !attributes.locked && 'all',
          }"
        ></Container>
      </template>
    </template>
    <div
      v-if="!isRowLoaded || !control || !xoneDataObject || !rowInfo.visible"
      :style="{
        width: rowInfo.width && rowInfo.width,
        height: rowInfo.height && rowInfo.height,
      }"
    ></div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  provide,
  ref,
  Ref,
  PropType,
  ComputedRef,
  watch,
} from "vue";

import AppDataHandler from "../../../composables/AppDataHandler";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import {
  xoneAttributesHandler,
  PropAttributes,
  ContainerAttributes,
} from "../../../composables/XoneAttributesHandler";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";
import { RowInfo } from "../../../composables/ContentsLoaderHandler";

export default {
  name: "ContentsRow",

  props: {
    /**
     * rowInfo
     * @type {PropType<RowInfo>}
     */
    rowInfo: { type: Object, required: true },
    controlHeight: { type: Number, default: 0 },
    /**
     * attributes
     * @type {PropType<PropAttributes>}
     */
    attributes: { type: Object, required: true },
    isDisableEdit: { type: Boolean, required: true },
    controlWidth: { type: Number, default: 0 },
    isSlideView: { type: Boolean, default: false },
    isExpanView: { type: Boolean, default: false },
  },

  setup(props) {
    const isRowLoaded = ref(false);

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = inject("contents");

    /**
     * XoneDataObject
     * @type {Ref<XoneDataObject>}
     */
    const xoneDataObject = ref(null);

    /**
     * Layout
     * @type {Ref<Object>}
     */
    const layout = ref(null);

    /**
     * Control attributes and childs layout
     * @type {ComputedRef<Array>}
     */
    const control = computed(() =>
      layout.value?.controls?.find((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          containerAttributes.node === "group" &&
          !containerAttributes.fixed &&
          !containerAttributes.drawerOrientation
        );
      })
    );

    /**
     * Contents coll attributes
     * @type {ComputedRef<ContainerAttributes>}
     */
    const contentsAttributes = computed(() =>
      xoneAttributesHandler.getContainerAttributes(
        contents.value?.getLayout().attributes
      )
    );

    /**
     * selectedItem
     * @type {Ref<number>}
     */
    const selectedItem = inject("selectedItem");

    let isOnContentsRowClickExecuting = false;
    /**
     * On Click contents row
     */
    const onContentsRowClick = async () => {
      // is locked or is Edit in Row
      if (
        props.attributes.editInRow ||
        props.attributes.locked ||
        props.isDisableEdit
      )
        return;

      if (isOnContentsRowClickExecuting) return;
      isOnContentsRowClickExecuting = true;
      //
      // execute selecteditem node
      try {
        if (await xoneDataObject.value.ExecuteNode("selecteditem")) {
          isOnContentsRowClickExecuting = false;
          selectedItem.value = props.rowInfo.id;
          return;
        }
      } catch {}
      //
      // expanview
      if (props.isExpanView) {
        isOnContentsRowClickExecuting = false;
        return onExpanItemSelected(props.rowInfo);
      }
      //
      // open edit view
      AppDataHandler.pushXoneDataObject(xoneDataObject.value);
      isOnContentsRowClickExecuting = false;
    };

    /**
     * Load contents row async
     */
    const doLoadAsync = async (isFirstLoad = true) => {
      isRowLoaded.value = false;

      if (contents.value.m_xmlNode.getAttrValue("special") === "true")
        xoneDataObject.value = await contents.value.get(props.rowInfo.id);
      else
        xoneDataObject.value = await contents.value.get(props.rowInfo.recordId);

      if (!xoneDataObject.value) return;

      // IsFirstLoad -> increment loadedRowsLength
      if (isFirstLoad) loadedRowsLength.value++;

      // Set Do Model Reactive
      xoneDataObject.value.doModelReactive();

      if (props.attributes.editInRow) xoneDataObject.value._editInRow = true;

      getLayout();

      isRowLoaded.value = true;

      // Execute nodes
      xoneDataObject.value
        .ExecuteNode("before-edit")
        .then(() => xoneDataObject.value.ExecuteNode("load"))
        .then(() => xoneDataObject.value.ExecuteNode("after-edit"))
        .catch(console.error);
    };

    const getLayout = () => {
      if (!xoneDataObject.value) return;
      // expanview Layout
      if (props.isExpanView) {
        expanLayout.value = xoneDataObject.value.getLayout(1);
      }
      // Layout
      layout.value = xoneDataObject.value.getLayout(4);
      xoneDataObject.value.setLayout(layout.value);
    };

    /**
     * Viewport size conditions sm / md / lg
     * @type {Ref<string>}
     */
    const sizeConditions = inject("sizeConditions");

    /**
     * orientation vertical / horizontal
     * @type {Ref<string>}
     */
    const orientation = inject("orientation");

    watch(
      () => sizeConditions.value,
      () => getLayout()
    );

    watch(
      () => orientation.value,
      () => getLayout()
    );

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    //
    // Load / Refresh Item
    const loadedRowsLength = inject("loadedRowsLength");
    onMounted(() =>
      doLoadAsync().then(() =>
        props.rowInfo.setRefresh(() => doLoadAsync(false))
      )
    );

    // Provide contents info
    provide("objectInfo", {
      isContents: true,
      editInRow: props.attributes.editInRow,
      autosave: props.attributes.autosave,
      recordIndex: props.rowInfo.id,
    });

    let colorView;

    /**
     * m_xmlNode
     * @type {{m_xmlNode:XmlNode}}
     */
    const { m_xmlNode } = contents.value;

    m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
      if (e.getAttrValue("colorview") === "true")
        colorView = e.getAttrValue("name");
      if (e.getAttrValue("cell-selected-bgcolor") === "true")
        colorView = e.getAttrValue("name");
    });

    // Row background color
    const backgroundColor = computed(() => {
      if (colorView && xoneDataObject.value)
        return xoneDataObject.value[colorView];
      else if (
        selectedItem.value &&
        props.rowInfo.id === selectedItem.value &&
        contentsAttributes.value.cellSelectedBgColor
      )
        return contentsAttributes.value.cellSelectedBgColor;
      else
        return Number(props.rowInfo.id) % 2 === 0
          ? contentsAttributes.value.cellEvenColor
          : contentsAttributes.value.cellOddColor;
    });

    //
    // Expanview
    const onExpanItemSelected = inject("onExpanItemSelected");

    /**
     * Layout
     * @type {Ref<Object>}
     */
    const expanLayout = ref(null);

    /**
     * Control attributes and childs layout
     * @type {ComputedRef<Array>}
     */
    const expanControl = computed(() => {
      if (!props.isExpanView) return null;
      return expanLayout.value?.controls?.find((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          containerAttributes.node === "group" &&
          !containerAttributes.fixed &&
          !containerAttributes.drawerOrientation
        );
      });
    });

    // ExecuteNode onexpand / oncollapse
    watch(
      () => props.rowInfo.isExpanded,
      (newValue) => {
        if (!xoneDataObject.value) return;
        if (newValue) {
          // xoneDataObject.value.setLayout(expanLayout.value); creo que esto no hace falta
          xoneDataObject.value.ExecuteNode("onexpand").catch(console.error);
        } else {
          // xoneDataObject.value.setLayout(layout.value); creo que esto no hace falta
          xoneDataObject.value?.ExecuteNode("oncollapse").catch(console.error);
        }
      }
    );

    return {
      isRowLoaded,
      control,
      contentsAttributes,
      xoneDataObject,
      onContentsRowClick,
      breadcumbId,
      backgroundColor,
      expanControl,
    };
  },
};
</script>

<style>
.xone-contents-row {
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  animation: fadeIn 0.3s;
}

.xone-slidesnap {
  scroll-snap-align: center;
}
/* .xone-expanded-row { */
/* animation: slideDown 0.3s; */
/* } */

.xone-contents-row-basic {
  cursor: pointer;
}

.xone-contents-row-basic:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.12);
}
</style>