import { computed, watch } from "@vue/runtime-core";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import AppDataHandler from "./AppDataHandler";

class XoneViewsHandler {
  /**
   * _instance
   * @type {XoneViewsHandler}
   */
  static _instance;

  /**
   * views
   * @type {Map<string, XoneView>}
   */
  viewsMap = new Map();

  constructor() {
    if (XoneViewsHandler._instance) return XoneViewsHandler._instance;
    XoneViewsHandler._instance = this;
  }

  /**
   * Add View
   * @param {XoneView} xoneView
   */
  addView(xoneView) {
    this.viewsMap.set(xoneView.getXoneDataObject().getHashId(), xoneView);
  }

  /**
   * Get View
   * @param {XoneDataObject} xoneDataObject
   * @returns {XoneView}
   */
  getView(xoneDataObject) {
    return this.viewsMap.has(xoneDataObject.getHashId())
      ? this.viewsMap.get(xoneDataObject.getHashId())
      : null;
  }

  /**
   * cleanRemovedViews
   */
  cleanRemovedViews() {
    const xoneHashIds = computed(() =>
      AppDataHandler.getBreadcumbs().map((e) => e.xoneHashId)
    );
    watch(
      () => xoneHashIds.value.length,
      () =>
        Array.from(this.viewsMap.keys()).forEach((e) => {
          if (!xoneHashIds.value.includes(e)) this.viewsMap.delete(e);
        })
    );
  }
}

/**
 * @type {XoneViewsHandler}
 */
const xoneViewsHandler = new XoneViewsHandler();

// Monitorice and clean removed views
Promise.resolve().then(() => xoneViewsHandler.cleanRemovedViews());

export const getView = (/** @type {XoneDataObject} */ xoneDataObject) =>
  xoneViewsHandler.getView(xoneDataObject);

export class XoneView {
  /**
   * bindedEvents
   * @type {Array<object>}
   */
  _bindedEvents = [];

  /**
   * @type {XoneDataObject}
   */
  _xoneDataObject;

  /**
   * constructor
   * @param {XoneDataObject} xoneDataObject
   */
  constructor(xoneDataObject) {
    this._xoneDataObject = xoneDataObject;
    xoneViewsHandler.addView(this);
  }

  get bindedEvents() {
    return this._bindedEvents;
  }

  /**
   * addControl
   * @param {XoneControl} control
   */
  addControl(control) {
    this[control.name] = control;
  }

  getXoneDataObject() {
    return this._xoneDataObject;
  }

  /**
   * Bind event
   * @param  {...string} Args
   */
  bind(...Args) {
    if (!Args || Args.length < 3)
      return console.error(
        "Error calling bind method. You need 3 arguments at least."
      );

    let bindedEvent = this._bindedEvents.find(
      (e) =>
        e.field === Args[0] && e.eventName === Args[1].toString().toLowerCase()
    );

    if (!bindedEvent)
      bindedEvent = {
        fieldName: Args[0],
        eventName: Args[1].toString().toLowerCase(),
      };

    if (Args.length === 3) bindedEvent.action = Args[2];
    else {
      bindedEvent.params = Args[2];
      bindedEvent.action = Args[3];
    }

    // Add event bind
    this._bindedEvents.push(bindedEvent);
  }

  /**
   * exit current view
   */
  exit() {
    AppDataHandler.clearBreadcumbsFromXoneHashId(
      this._xoneDataObject["_XoneHashId"]
    );
  }

  /**
   * Refresh
   * @param  {...any} Props
   */
  refresh(...Props) {
    const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
    props.forEach((/** @type {*} */ e) => {
      if (this[e]?.refresh) this[e].refresh();
    });
    if (Props.length === 0)
      Object.values(this).forEach((e) => {
        if (!e) return;
        if (e.refresh) e.refresh();
      });
  }

  /**
   * Refresh All
   */
  refreshAll() {
    this.refresh();
  }

  /**
   * refreshValue
   * @param {*} Props
   */
  refreshValue = (Props) => {
    this.refresh(Props);
  };
}

export class XoneControl {
  /**
   * @type {string}
   */
  name;

  refresh = () => { };

  refreshValue = () => { };

  /**
   * constructor
   * @param {string} name
   */
  constructor(name) {
    this.name = name;
  }
}
